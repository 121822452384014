var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "header",
      { class: ["site-header print-hidden", _vm.header_style + "-style"] },
      [
        _c(
          "div",
          {
            class: [
              "site-header__top hidden  lg:block",
              _vm.header_style === "light" ? "bg-white" : "bg-brown",
              _vm.text_color
            ]
          },
          [_c("utility-nav")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "site-header__main" }, [
          _c(
            "div",
            {
              class: [
                "relative pt-5-safe pb-5",
                _vm.header_style === "dark" ? "lg:pt-5-safe" : "lg:pt-1-safe"
              ]
            },
            [
              _c("div", { staticClass: "bg-white" }, [
                _c("div", { staticClass: "relative z-40" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative container mx-auto px-5-safe flex justify-between items-center lg:items-end"
                    },
                    [
                      _c("div", [
                        _c("a", { attrs: { href: _vm.logo_url } }, [
                          _c("img", {
                            staticClass:
                              "site-logo m-0 block h-12 lg:h-14 pr-4",
                            attrs: {
                              src:
                                _vm.header_style === "light"
                                  ? _vm.logo_dark_src
                                  : _vm.logo_light_src,
                              alt: _vm.logo_alt
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "-mr-2 -my-2 flex flex-row items-center lg:hidden"
                        },
                        [
                          _c("div", { staticClass: "cart-btn relative mr-4" }, [
                            _c(
                              "a",
                              { attrs: { href: "/shop/cart" } },
                              [
                                _c(
                                  "base-icon",
                                  {
                                    staticClass: "text-brown h-8 w-8",
                                    attrs: {
                                      "icon-name": "cart",
                                      width: "28",
                                      height: "28",
                                      viewbox: "0 0 28 28"
                                    }
                                  },
                                  [_c("icon-cart")],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.$root.totalCartQuantity > 0,
                                      expression: "$root.totalCartQuantity > 0"
                                    }
                                  ],
                                  staticClass: "block circle bg-red"
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.toggleMobileMenu()
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Open menu")
                              ]),
                              _vm._v(" "),
                              _c(
                                "svg",
                                {
                                  staticClass: "h-12 w-12",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "none",
                                    viewBox: "0 0 24 24",
                                    stroke: "currentColor",
                                    "aria-hidden": "true"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      "stroke-width": "2",
                                      d: "M4 6h16M4 12h16M4 18h16"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "hidden lg:flex md:items-center md:justify-between"
                        },
                        [
                          _c(
                            "nav",
                            {
                              staticClass:
                                "flex font-bold text-xl xl:text-hxl text-brown"
                            },
                            _vm._l(_vm.main_nav, function(item_l1) {
                              return _c(
                                "div",
                                { staticClass: "ml-7" },
                                [
                                  item_l1.depth === 1
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "top-link",
                                            attrs: {
                                              href: item_l1.url,
                                              target: item_l1.target
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text relative" },
                                              [_vm._v(_vm._s(item_l1.text))]
                                            )
                                          ]
                                        )
                                      ]
                                    : [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "top-link font-bold",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.toggleDropdown(
                                                  item_l1.text
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                class: [
                                                  "relative",
                                                  item_l1.text ===
                                                  _vm.activeMenu
                                                    ? "active-text"
                                                    : "text"
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(item_l1.text) +
                                                    "\n                        "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "base-icon",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      item_l1.children.length >
                                                      0,
                                                    expression:
                                                      "item_l1.children.length > 0"
                                                  }
                                                ],
                                                class: [
                                                  "angle inline",
                                                  item_l1.text ===
                                                  _vm.activeMenu
                                                    ? "rotated"
                                                    : ""
                                                ],
                                                attrs: {
                                                  "icon-name": "angle-down",
                                                  width: "14",
                                                  height: "7",
                                                  viewbox: "0 0 17 9"
                                                }
                                              },
                                              [_c("icon-angle-down")],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "on-clickaway",
                                                rawName: "v-on-clickaway",
                                                value: _vm.closeDropdown,
                                                expression: "closeDropdown"
                                              }
                                            ],
                                            class: [
                                              "absolute hidden z-10 text-black font-regular font-base mt-4 items-center justify-center max-w-full shadow-sm",
                                              item_l1.depth !== 2 &&
                                              item_l1.depth
                                                ? "w-full transform-center left-center"
                                                : "",
                                              item_l1.text === _vm.activeMenu
                                                ? "md:flex"
                                                : ""
                                            ]
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "bg-taupe flex w-full"
                                              },
                                              [
                                                item_l1.ctas.left
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "hidden lg:block w-1/4 px-6 py-10"
                                                      },
                                                      [
                                                        _c("header-cta", {
                                                          attrs: {
                                                            cta:
                                                              item_l1.ctas.left
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    class:
                                                      (item_l1.ctas.right
                                                        ? "flex-1"
                                                        : "w-full") +
                                                      " px-6 py-10"
                                                  },
                                                  [
                                                    item_l1.depth < 3
                                                      ? [
                                                          _c(
                                                            "a",
                                                            {
                                                              class:
                                                                "text-2hxl font-bold pb-4 my-2 border-b-1 border-black " +
                                                                (item_l1.url &&
                                                                item_l1.url
                                                                  .length > 0
                                                                  ? "hover:border-red hover:text-red"
                                                                  : ""),
                                                              attrs: {
                                                                href:
                                                                  item_l1.url,
                                                                target:
                                                                  item_l1.target
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                " +
                                                                  _vm._s(
                                                                    item_l1.text
                                                                  ) +
                                                                  "\n                              "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex flex-row flex-wrap"
                                                      },
                                                      [
                                                        item_l1.depth >= 3
                                                          ? [
                                                              _vm._l(
                                                                item_l1.children,
                                                                function(
                                                                  item_l2
                                                                ) {
                                                                  return [
                                                                    item_l2.type ===
                                                                    "navigationItem"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            class:
                                                                              "flex flex-col flex-grow px-5-safe " +
                                                                              (item_l2.text ==
                                                                                "Reconyx Professional Cameras" ||
                                                                              item_l2.text ==
                                                                                "Reconyx Economy Cameras"
                                                                                ? "reconyx-level"
                                                                                : "w-1/3")
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                class:
                                                                                  "text-2hxl font-bold py-4 border-b-1 border-black " +
                                                                                  (item_l2.url &&
                                                                                  item_l2
                                                                                    .url
                                                                                    .length >
                                                                                    0
                                                                                    ? "hover:border-red"
                                                                                    : ""),
                                                                                attrs: {
                                                                                  href:
                                                                                    item_l2.url,
                                                                                  target:
                                                                                    item_l2.target
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                      " +
                                                                                    _vm._s(
                                                                                      item_l2.text
                                                                                    ) +
                                                                                    "\n                                    "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "ul",
                                                                              {
                                                                                staticClass:
                                                                                  "menu-list flex flex-col py-4"
                                                                              },
                                                                              [
                                                                                _vm._l(
                                                                                  item_l2.children,
                                                                                  function(
                                                                                    item_l3
                                                                                  ) {
                                                                                    return [
                                                                                      _c(
                                                                                        "li",
                                                                                        {
                                                                                          staticClass:
                                                                                            "border-taupe border-b-1 recon-l4 hover:border-red"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "a",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-lg flex w-full py-2",
                                                                                              attrs: {
                                                                                                href:
                                                                                                  item_l3.url,
                                                                                                target:
                                                                                                  item_l3.target
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                            " +
                                                                                                  _vm._s(
                                                                                                    item_l3.text
                                                                                                  ) +
                                                                                                  "\n                                          "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _vm._l(
                                                                                        item_l3.children,
                                                                                        function(
                                                                                          item_l4
                                                                                        ) {
                                                                                          return _c(
                                                                                            "div",
                                                                                            [
                                                                                              _c(
                                                                                                "li",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "border-taupe border-b-1 recon-l4 hover:border-red"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "a",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "text-lg flex w-full pl-6 py-2",
                                                                                                      attrs: {
                                                                                                        href:
                                                                                                          item_l4.url,
                                                                                                        target:
                                                                                                          item_l4.target
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                              " +
                                                                                                          _vm._s(
                                                                                                            item_l4.text
                                                                                                          ) +
                                                                                                          "\n                                            "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _vm._l(
                                                                                                item_l4.children,
                                                                                                function(
                                                                                                  item_l5
                                                                                                ) {
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "li",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "border-b-1 border-taupe hover:border-red"
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "a",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "text-lg flex w-full pl-10 py-2",
                                                                                                            attrs: {
                                                                                                              href:
                                                                                                                item_l5.url,
                                                                                                              target:
                                                                                                                item_l5.target
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "\n                                                " +
                                                                                                                _vm._s(
                                                                                                                  item_l5.text
                                                                                                                ) +
                                                                                                                "\n                                              "
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ]
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            2
                                                                                          )
                                                                                        }
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                )
                                                                              ],
                                                                              2
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                }
                                                              )
                                                            ]
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        item_l1.depth === 2
                                                          ? [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex w-full flex-wrap mt-8"
                                                                },
                                                                [
                                                                  _c(
                                                                    "ul",
                                                                    {
                                                                      staticClass:
                                                                        "menu-list w-full"
                                                                    },
                                                                    _vm._l(
                                                                      item_l1.children,
                                                                      function(
                                                                        item_l2
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            staticClass:
                                                                              "border-taupe border-b-1 hover:border-red"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                staticClass:
                                                                                  "flex w-full py-2",
                                                                                attrs: {
                                                                                  href:
                                                                                    item_l2.url,
                                                                                  target:
                                                                                    item_l2.target
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item_l2.text
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex flex-row flex-wrap"
                                                      },
                                                      _vm._l(
                                                        item_l1.children,
                                                        function(item_l2) {
                                                          return item_l2.type ===
                                                            "ctaButton"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex flex-row pl-5-safe pt-8"
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-button",
                                                                    {
                                                                      attrs: {
                                                                        url:
                                                                          item_l2.url,
                                                                        label:
                                                                          item_l2.text,
                                                                        color:
                                                                          item_l2
                                                                            .ctaButton
                                                                            .color,
                                                                        outline:
                                                                          item_l2
                                                                            .ctaButton
                                                                            .outlined,
                                                                        thin_outline: true,
                                                                        target:
                                                                          item_l2.target,
                                                                        size:
                                                                          "wider",
                                                                        class_names:
                                                                          "w-full"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                item_l1.ctas.right
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "hidden lg:block w-1/4 px-6 py-10"
                                                      },
                                                      [
                                                        _c("header-cta", {
                                                          attrs: {
                                                            cta:
                                                              item_l1.ctas.right
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "on-clickaway",
                        rawName: "v-on-clickaway",
                        value: _vm.closeMobileMenu,
                        expression: "closeMobileMenu"
                      }
                    ],
                    class: [
                      "absolute z-40 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden",
                      _vm.mobileMenuActive ? "" : "hidden"
                    ]
                  },
                  [
                    _c("div", { staticClass: "shadow bg-white" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-between pt-5 pr-5 pl-8"
                          },
                          [
                            _c("div", { staticClass: "cart-btn relative" }, [
                              _c(
                                "a",
                                { attrs: { href: "/shop/cart" } },
                                [
                                  _c(
                                    "base-icon",
                                    {
                                      staticClass: "text-brown h-10 w-10",
                                      attrs: {
                                        "icon-name": "cart",
                                        width: "28",
                                        height: "28",
                                        viewbox: "0 0 28 28"
                                      }
                                    },
                                    [_c("icon-cart")],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.$root.totalCartQuantity > 0,
                                        expression:
                                          "$root.totalCartQuantity > 0"
                                      }
                                    ],
                                    staticClass: "block circle bg-red"
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "-mr-2" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.toggleMobileMenu()
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Close menu")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "h-12 w-12",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        fill: "none",
                                        viewBox: "0 0 24 24",
                                        stroke: "currentColor",
                                        "aria-hidden": "true"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d: "M6 18L18 6M6 6l12 12"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("nav", [
                            _c(
                              "ul",
                              { staticClass: "mt-2" },
                              [
                                _vm.currentNavItem
                                  ? _c("li", [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "leading-loosest py-2 px-8 text-base font-bold w-full text-left flex items-center",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.exitMobileSubnav(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "base-icon",
                                            {
                                              staticClass: "arrow",
                                              attrs: {
                                                "icon-name": "angle-left",
                                                width: "6",
                                                height: "8",
                                                viewbox: "0 0 9 17"
                                              }
                                            },
                                            [_c("icon-angle-left")],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "pl-2" }, [
                                            _vm._v("Back")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "block leading-loosest pb-2 pt-8 px-8 text-3xl font-bold w-full text-left"
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.currentNavItem.text
                                                ) +
                                                "\n                          "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.currentMenu, function(item) {
                                  return [
                                    _c("li", [
                                      item.type === "ctaButton"
                                        ? _c(
                                            "div",
                                            { staticClass: "px-8 py-2" },
                                            [
                                              _c("base-button", {
                                                attrs: {
                                                  url: item.url,
                                                  label: item.text,
                                                  color: item.ctaButton.color,
                                                  outline:
                                                    item.ctaButton.outlined,
                                                  thin_outline: true,
                                                  target: item.target,
                                                  size: "wider",
                                                  class_names: "w-3/5 sm:w-2/5"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : item.children &&
                                          item.children.length === 0
                                        ? _c(
                                            "a",
                                            {
                                              class: [
                                                "leading-loosest py-2 px-8 text-lg w-full text-left flex items-center",
                                                item.level === 1
                                                  ? "font-bold"
                                                  : ""
                                              ],
                                              attrs: {
                                                href: item.url,
                                                target: item.target
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.text))
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "pl-3" },
                                                [
                                                  _c(
                                                    "base-icon",
                                                    {
                                                      staticClass: "arrow",
                                                      attrs: {
                                                        "icon-name":
                                                          "angle-left",
                                                        width: "6",
                                                        height: "10",
                                                        viewbox: "0 0 9 17"
                                                      }
                                                    },
                                                    [_c("icon-angle-right")],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _c(
                                            "button",
                                            {
                                              class: [
                                                "leading-loosest py-2 px-8 text-lg w-full text-left flex items-center font-bold",
                                                item.level === 1
                                                  ? "font-bold"
                                                  : ""
                                              ],
                                              attrs: { type: "button" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.enterMobileSubnav(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.text))
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "pl-3" },
                                                [
                                                  _c(
                                                    "base-icon",
                                                    {
                                                      staticClass: "arrow",
                                                      attrs: {
                                                        "icon-name":
                                                          "angle-left",
                                                        width: "6",
                                                        height: "10",
                                                        viewbox: "0 0 9 17"
                                                      }
                                                    },
                                                    [_c("icon-angle-right")],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                    ])
                                  ]
                                }),
                                _vm._v(" "),
                                _vm.utility_nav
                                  ? _c("li", { staticClass: "px-8 py-6" }, [
                                      _c("hr", { staticClass: "border-grey" })
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.utility_nav, function(item) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.display.value !==
                                              "desktopOnly",
                                            expression:
                                              "item.display.value !== 'desktopOnly'"
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            class: [
                                              "block leading-loosest py-2 px-8 text-lg",
                                              item.navHighlight
                                                ? "bg-red text-white"
                                                : ""
                                            ],
                                            attrs: { href: item.url }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  item.mobileText !== "" &&
                                                    item.mobileText !== null
                                                    ? item.mobileText
                                                    : item.text
                                                ) +
                                                "\n                          "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }