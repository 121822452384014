<template>
  <div id="accessories-result" class="relative lg:py-24 py-8">
    <div v-if="ajaxError" class="mb-10 text-red text-lg" v-html="ajaxError"></div>
    <div :class="[isLoading ? 'opacity-25' : '']">
      <div class="flex flex-wrap items-stretch justify-between relative"
        v-if="items !== null && items !== undefined && items.length > 0">
        <div v-for="(item, i) in items" class="mx-auto md:mx-0 w-full md:w-p48 lg:w-p31 mb-8">
          <accessory-card :key="i" :accessory="item"></accessory-card>
        </div>
        <div class="w-full md:w-p48 lg:w-p31 mb-8" v-if="items !== null && items !== undefined && items.length % 3 !== 0">
        </div>
      </div>
      <no-results :results="items" :filters="true" @resetFilters="reset" v-if="!isLoading"></no-results>
      <!-- <add-to-cart v-if="this.items !== null" :items="this.items"></add-to-cart> -->
      <div class="mt-6">
        <base-pagination :max_visible_buttons="numPages >= 3 ? 3 : parseInt(numPages)"
          :per_page="parseInt($parent.limit)" :num_pages="parseInt(numPages)" :current_page="$parent.currentPage"
          :total="parseInt(total)" :show_prev_next_btns="true" :pagination_disabled="isLoading"
          @pagechanged="onPageChange"></base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo);

export default {
  data: function () {
    return {
      items: this.initial_items ?? null,
      total: null,
      numPages: this.num_pages ?? 0,
      isLoading: false,
      ajaxError: "",
    };
  },
  props: [ 'initial_items', 'num_pages' ],
  components: {
    AccessoryCard: () => import(/* webpackChunkName: "accessory-card" */ "./AccessoryCard.vue"),
    BasePagination: () => import(/* webpackChunkName: "base-pagination" */ "../components/BasePagination.vue"),
    NoResults: () => import(/* webpackChunkName: "no-results" */ "../components/NoResults.vue"),
    // AddToCart: () => import(/* webpackChunkName: "add-to-cart" */ "./AddToCart.vue")
  },
  methods: {
    reset() {
      this.$parent.selectedAssociatedProduct = "";
      this.$parent.selectedAccessoryCategory = "";
    },
    updateResults() {
      let self = this,
        queryStrings = [],
        ajaxUrl =
          "/actions/site-module/products/get-accessories?limit=" + this.$parent.limit + "&page=" + this.$parent.currentPage;
      if (this.localSelectedAssociatedProduct !== "") {
        queryStrings.push("ap=" + this.localSelectedAssociatedProduct);
      }
      if (this.localSelectedAccessoryCategory !== "") {
        queryStrings.push("ac=" + this.localSelectedAccessoryCategory);
      }
      if (queryStrings.length > 0) {
        ajaxUrl += "&" + queryStrings.join("&");
      }
      self.isLoading = true;
      self.ajaxError = "";
      VueAxios.get(ajaxUrl)
        .then(response => {
          self.isLoading = false;
          let data = response.data || null;
          if (data.items !== undefined) {
            self.items = data.items.data || null;
            self.numPages = data.items.metadata.pages || null;
            self.total = data.items.metadata.total || null;
            self.$emit("updateUrl");
            if (self.$parent.currentPage > 1) {
              self.scrollToTop();
            }
          } else {
            self.ajaxError = "An error occurred. Please try again.";
            self.scrollToTop();
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
          self.scrollToTop();
        });
    },
    scrollToTop() {
      VueScrollTo.scrollTo("#accessories-result", 350, {
        offset: 5
      });
    },
    onPageChange(new_current_page) {
      this.$emit("setPageNumber", new_current_page);
      this.updateResults();
    }
  },
  computed: {
    localSelectedAssociatedProduct() {
      return this.$parent.selectedAssociatedProduct;
    },
    localSelectedAccessoryCategory() {
      return this.$parent.selectedAccessoryCategory;
    }
  },
  watch: {
    localSelectedAssociatedProduct: function () {
      this.$emit("setPageNumber", 1);
      this.updateResults();
    },
    localSelectedAccessoryCategory: function () {
      this.$emit("setPageNumber", 1);
      this.updateResults();
    }
  },
  mounted() {
    window.accessories = this.items;

    if (!this.items) {
      this.updateResults();
    }

  }
}
</script>
