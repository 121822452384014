var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cta.type === "image"
    ? _c(
        "div",
        { staticClass: "flex flex-col gap-y-4 text-base" },
        [
          _c("base-image", {
            attrs: {
              disable_lazy: true,
              src: _vm.cta.image.src,
              retina_src: _vm.cta.image.retinaSrc,
              alt: _vm.cta.image.alt,
              class_names: "w-full h-48 object-cover"
            }
          }),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.cta.heading))]),
          _vm._v(" "),
          _vm.cta.imageLink
            ? _c(
                "a",
                {
                  staticClass:
                    "uppercase underline font-bold cursor-pointer hover:text-red",
                  attrs: { href: "cta.imageLink" }
                },
                [_vm._v(_vm._s(_vm.cta.imageLinkText))]
              )
            : _vm.cta.imageLinkText
            ? _c("p", { staticClass: "font-bold uppercase" }, [
                _vm._v(_vm._s(_vm.cta.imageLinkText))
              ])
            : _vm._e()
        ],
        1
      )
    : _vm.cta.type === "richText"
    ? _c(
        "div",
        [
          _c("h1", { staticClass: "text-brown-lighter font-bold text-6xl" }, [
            _vm._v(_vm._s(_vm.cta.title))
          ]),
          _vm._v(" "),
          _c("base-rich-text", {
            attrs: { class_names: "mt-6" },
            domProps: { innerHTML: _vm._s(_vm.cta.text) }
          }),
          _vm._v(" "),
          _vm.cta.buttonLink && _vm.cta.buttonText
            ? _c("base-button", {
                attrs: {
                  url: _vm.cta.buttonLink,
                  label: _vm.cta.buttonText,
                  color: "red",
                  class_names: "mt-6"
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm.cta.type == "upcomingTraining"
    ? _c(
        "div",
        [
          _c(
            "h1",
            { staticClass: "text-brown-lighter font-bold text-6xl mb-6" },
            [_vm._v("Upcoming Trainings")]
          ),
          _vm._v(" "),
          _vm._l(_vm.cta.events, function(event) {
            return _c("div", { staticClass: "mb-6" }, [
              _c("h2", { staticClass: "font-bold mb-2" }, [
                _vm._v(_vm._s(event.date))
              ]),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "hover:text-red", attrs: { href: event.url } },
                [_vm._v(_vm._s(event.name))]
              )
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mt-16" }, [
            _vm._v("\n    Explore our library of product videos.\n  ")
          ]),
          _vm._v(" "),
          _c("base-button", {
            attrs: {
              url: "/resources/video-tutorials",
              label: "Video Tutorials",
              color: "red",
              class_names: "mt-6"
            }
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }