<template>
  <footer class="site-footer print-hidden text-taupe bg-grey-lightest">
    <div class="text-black container mx-auto py-16 px-5-safe">
      <div class="flex flex-col md:flex-row gap-x-12 gap-y-12">
        <div class="w-full md:w-1/3">
          <a :href="logo_href" class="footer-logo">
            <img class="max-h-10" :src="logo_src" />
          </a>
          <div class="contact-details lg:text-lg leading-loose mt-8 mb-8 text-left">
            <slot name="contact-details"></slot>
          </div>
          <div class="contact-details lg:text-lg leading-loose mt-8 text-left">
            <social-icon
              v-for="(icon, i) in social_icons"
              :key="`desktop-mobile-${i}`"
              :url="icon.url"
              :type="icon.website_value"
              :class_names="['hidden lg:inline-block text-black tr-color hover:text-brown-lightest', i > 0 ? 'ml-2 lg:ml-4' : '']"
              icon_size="28"
            ></social-icon>
            <social-icon
              v-for="(icon, i) in social_icons"
              :key="`social-mobile-${i}`"
              :url="icon.url"
              :type="icon.website_value"
              :class_names="['inline-block lg:hidden text-black tr-color hover:text-brown-lightest', i > 0 ? 'ml-2 lg:ml-4' : '']"
              icon_size="24"
            ></social-icon>
          </div>
        </div>
        <div class="w-full md:w-2/3 text-center md:text-left flex flex-col md:flex-row flex-wrap gap-x-12 gap-y-8 md:gap-y-12">
          <div
            v-for="(column, i) in footer_content.columns"
            :key="`nav-column-${i}`"
            class="text-left flex-1 flex flex-col gap-y-4 md:gap-y-8"
          >
            <base-heading size="h3" :uppercase="true" color="black">
              {{ column.headingText }}
            </base-heading>
            <nav class="footer-nav lg:text-lg leading-tight text-black">
              <ul>
                <li class="my-3" v-for="(item, i) in column.navigationItems" :key="`nav-link-${i}`">
                  <a
                    :href="item.url"
                    :target="item.target"
                    class="inline-block relative tr-color hover:underline hover:text-brown-lightest"
                  >
                    {{ item.text }}</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="container mx-auto py-8">
        <div class="flex flex-col-reverse md:flex-row md:justify-between gap-x-12 gap-y-8">
          <div>
            <p><slot name="footer-footnote"></slot></p>
          </div>
          <div class="flex-1 flex flex-col md:flex-row md:justify-end gap-x-8 gap-y-8">
            <a
              v-for="(link, i) in footer_content.supplementaryLinks"
              :key="`supplementary-link-${i}`"
              :href="link.url"
              :target="link.target"
            >
              {{ link.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import HelpersMixin from "../../mixins/Helpers";

export default {
  props: ["logo_href", "logo_src", "footer_content", "social_icons"],
  mixins: [HelpersMixin],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../BaseImage.vue"),
    SocialIcon: () => import(/* webpackChunkName: "social-icon" */ "../SocialIcon.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../BaseIcon.vue"),
    IconAngleRight: () => import(/* webpackChunkName: "icon-angle-right" */ "../../icons/IconAngleRight.vue")
  }
};
</script>
<style>
.site-footer {
  .footer-logo {
    @apply flex items-start;

    img {
      @apply mx-0 max-w-xs flex items-start !important;
    }
  }

  .contact-details {
    a {
      transition: color 0.15s;

      &:hover {
        color: #ecd2ab;
        text-decoration: underline;
      }
    }
  }
}
</style>
