var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "sidebar-products sidebar-block max-w-sm mx-auto",
        !_vm.is_first ? "mt-6" : ""
      ]
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.block.products.length > 0,
              expression: "block.products.length > 0"
            }
          ],
          staticClass: "mt-3"
        },
        _vm._l(_vm.block.products, function(product, i) {
          return _c("div", { key: i }, [
            _c(
              "h4",
              { staticClass: "text-xl text-green font-bold uppercase" },
              [
                _c(
                  product.url ? "a" : "span",
                  { tag: "component", attrs: { href: product.url } },
                  [_vm._v("\n          " + _vm._s(product.name) + "\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: product.src,
                    expression: "product.src"
                  }
                ],
                staticClass: "mt-3"
              },
              [
                _c(
                  product.url ? "a" : "span",
                  { tag: "component", attrs: { href: product.url } },
                  [
                    _c("base-image", {
                      attrs: {
                        src: product.src,
                        retina_src: product.srcRetina,
                        alt: product.alt
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: product.text,
                  expression: "product.text"
                }
              ],
              staticClass: "mt-3 rich-text text-xl",
              domProps: { innerHTML: _vm._s(product.text) }
            }),
            _vm._v(" "),
            product.buttonText && product.url
              ? _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("base-button", {
                      attrs: {
                        url: product.url,
                        label: product.buttonText,
                        color: "brown",
                        outline: true,
                        class_names: "w-full"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }