var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideOverlay,
          expression: "hideOverlay"
        }
      ],
      class: [
        "xl:w-2/5 lg:w-3/5 w-full transform-center left-center bg-grey-light",
        _vm.wrapperClasses
      ]
    },
    [
      _c(
        "header",
        { staticClass: "block relative py-4 px-8 border-b border-brown" },
        [
          _c(
            "div",
            {
              staticClass: "uppercase font-bold text-2xl text-left text-brown"
            },
            [_vm._v("\n      Added to your cart\n    ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "text-brown absolute right-0 centered-y pr-5",
              on: { click: _vm.hideOverlay }
            },
            [
              _c(
                "base-icon",
                {
                  attrs: {
                    "icon-name": "x",
                    width: "16",
                    height: "16",
                    viewbox: "0 0 23 23"
                  }
                },
                [_c("icon-x")],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "px-4 pt-4" }, [
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            {
              staticClass: "py-4 flex items-center w-full border-b border-brown"
            },
            [
              _c("base-image", {
                attrs: {
                  src: _vm.product.src,
                  retina_src: _vm.product.retina_src,
                  height: 50,
                  width: 150
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-left" }, [
                _c("div", {
                  staticClass: "font-bold text-xl text-brown",
                  domProps: { innerHTML: _vm._s(_vm.product.title) }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "text-sm mt-1" }, [
                  _vm._v("Product Code: " + _vm._s(_vm.product.code))
                ])
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-center sm:p-6 px-4 pt-4" },
          [
            _c("span", { staticClass: "pr-4 sm:pb-0 pb-4" }, [
              _c(
                "button",
                {
                  staticClass:
                    "button inline-block uppercase tr-all rounded font-bold leading-tighter text-center cursor-pointer text-lg xl:text-xl border-3 border-brown hover:border-brown-hover text-brown bg-white hover:bg-grey-light px-8 py-2 xl:py-3",
                  on: { click: _vm.hideOverlay }
                },
                [_vm._v("Keep\n        shopping")]
              )
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "pr-4 sm:pb-0 pb-4" },
              [
                _c("base-button", {
                  attrs: {
                    url: "/shop/cart",
                    label: "View Cart",
                    color: "brown",
                    outline: false,
                    size: "extrawide"
                  }
                })
              ],
              1
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }