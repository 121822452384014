<template>
  <div class="w-full flex flex-col gap-y-8">
    <add-to-cart-box-price-tiers-flyout v-show="!hasPriceOverride">
      <template slot="bulk_prices_text">
        <slot name="bulk_prices_text"></slot>
      </template>
    </add-to-cart-box-price-tiers-flyout>
    <div class="flex flex-row gap-x-4">
      <div :class="`${!showContactSales && !hasPriceOverride ? 'w-1/2' : ''} flex flex-col gap-y-4 justify-between`">
        <div
          class="stock text-base lg:text-lg uppercase"
          v-show="!hasPriceOverride"
          v-html="$parent.product.stock ? $parent.product.stock : 'In Stock'"
        ></div>
        <div class="flex flex-row items-end">
          <span class="font-bold text-3xl lg:text-4xl" v-html="listPrice"></span>
          <span class="text-xs lg:text-sm font-bold ml-1 mb-1" v-html="currency"></span>
        </div>
      </div>
      <div v-if="showContactSales || hasPriceOverride" class="flex flex-col">
        <a href="/sales" class="w-full text-center uppercase text-xl font-bold bg-red rounded text-white py-3 px-6">
          Contact Sales
        </a>
        <span class="italic mt-1">For additional details, contact our Sales team.</span>
      </div>
      <template v-else>
        <div class="flex flex-col gap-y-4 justify-end w-1/6">
          <span>Qty:</span>
          <div>
            <input
              type="number"
              pattern="[0-9]"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              step="1"
              min="0"
              id="quantity"
              :name="['quantity_' + $parent.product_code]"
              v-model="quantity"
              class="px-2 inline-block w-16 h-12 bg-white text-black text-xl font-bold rounded-lg text-center"
              value="1"
            />
          </div>
        </div>
        <div class="flex-grow flex flex-col gap-y-4 justify-end w-1/2">
          <button
            type="submit"
            @click.prevent="addToCart()"
            :disabled="isLoading || quantity < 1"
            :class="[
              'loading-btn bg-red text-base xl:text-xl inline-block font-bold rounded text-center h-12 w-full uppercase',
              isLoading || quantity < 1 ? 'text-grey' : 'text-white tr-color tr-bg hover:bg-red-hover',
              isLoading ? 'pl-8' : ''
            ]"
          >
            <span class="inline">Add to Cart</span>
            <base-icon icon-name="loading" width="12" height="12" viewbox="0 0 32 32" class="loading ml-1" v-if="isLoading">
              <icon-loading></icon-loading>
            </base-icon>
            <base-icon
              icon-name="icon-arrow-outward"
              width="10"
              height="10"
              viewbox="0 0 10 10"
              class="icon-arrow-outward ml-1"
              v-else
            >
              <icon-arrow-outward></icon-arrow-outward>
            </base-icon>
          </button>
        </div>
      </template>
    </div>
    <div v-show="$parent.has_price_footnote" class="text-base leading-normal">
      <slot name="price_footnote"></slot>
    </div>
    <add-to-cart-box-overlay v-if="showOverlay"></add-to-cart-box-overlay>
  </div>
</template>

<script>
import VueAxios from "../../../js/modules/vue-axios";
import { gaAddToCart } from "../../../js/modules/wa-analytics";

export default {
  props: ["class_names"],
  data: function() {
    return {
      isLoading: false,
      showOverlay: false,
      quantity: 1,
      essentialProductsCarouselNavigateTo: -1,
      ajaxError: ""
    };
  },
  components: {
    AddToCartBoxPriceTiersFlyout: () =>
      import(/* webpackChunkName: "add-to-cart-box-price-tiers-flyout" */ "./AddToCartBoxPriceTiersFlyout.vue"),
    AddToCartBoxOverlay: () => import(/* webpackChunkName: "add-to-cart-box-overlay" */ "./AddToCartBoxOverlay.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../../icons/IconLoading.vue"),
    IconArrowOutward: () => import(/* webpackChunkName: "icon-arrow-outward" */ "../../icons/IconArrowOutward.vue")
  },
  methods: {
    addToCart() {
      let self = this;
      self.isLoading = true;
      const productCode = self.$parent.product_code.replace(/\\/g, "\\\\");

      let formData = new FormData();
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append("quantity_" + productCode, self.quantity);
      formData.append("add_" + productCode, "");
      formData.append("action", "wildlife/shop/update");
      let options = {
        method: "POST",
        data: formData,
        url: "/"
      };
      self.ajaxError = "";
      VueAxios(options)
        .then(response => {
          let data = response.data || null,
            cart = data.cart || null;
          self.isLoading = false;
          if (data.success && cart) {
            self.$root.cart = cart;
            // set carousel slide to 0 (first) to initialize, otherwise it doesn't initialize show when the overlay appears
            self.essentialProductsCarouselNavigateTo = 0;
            self.showOverlay = true;

            // Send to Google Analytics
            gaAddToCart(
              [
                {
                  name: this.$parent.product_name,
                  id: this.$parent.product_code,
                  price: this.$parent.list_price,
                  brand: self.$root.getSiteFullName(),
                  quantity: self.quantity
                }
              ],
              {
                list: "Product page"
              },
              self.quantity,
              self.$root.currencyCode()
            );
          } else {
            self.ajaxError = "An error occurred. Please try again.";
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
        });
    }
  },
  computed: {
    showContactSales() {
      return this.$parent.requires_sales_contact === "true" || this.$parent.requires_sales_contact === true;
    },
    hasPriceOverride() {
      return this.$parent.price_override && this.$parent.price_override.length > 0;
    },
    listPrice() {
      // Show the price override if the item has one and requires sales contact
      return this.hasPriceOverride ? this.$parent.price_override : this.$root.formattedPrice(this.$parent.list_price);
    },
    currency() {
      return this.hasPriceOverride ? "" : this.$root.formattedCurrency();
    }
  }
};
</script>

<style scoped>
.wrapper {
  max-width: 400px;
}

.box {
  min-height: 150px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.stock >>> a {
  @apply underline;
}
</style>
