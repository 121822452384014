var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["input-product-quantity", _vm.class_names] }, [
    _c(
      "div",
      { staticClass: "relative h-full w-full" },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localQty,
              expression: "localQty"
            }
          ],
          class: [
            "w-full text-xl font-bold rounded-lg h-full text-center bg-white leading-tight",
            _vm.is_loading
              ? "text-white"
              : _vm.is_disabled
              ? "text-grey"
              : "text-black",
            _vm.border_color !== undefined
              ? "border-3 " +
                (_vm.is_disabled || _vm.is_loading
                  ? "border-grey-light"
                  : "border-" + _vm.border_color)
              : ""
          ],
          staticStyle: { height: "100%" },
          attrs: {
            type: "number",
            "aria-label": "Quantity",
            pattern: "[0-9]",
            onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
            step: "1",
            min: "0"
          },
          domProps: { value: _vm.localQty },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.keyEnter()
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.localQty = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "base-icon",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.is_loading,
                expression: "is_loading"
              }
            ],
            staticClass: "centered text-grey",
            attrs: {
              "icon-name": "loading",
              width: "24",
              height: "24",
              viewbox: "0 0 32 32"
            }
          },
          [_c("icon-loading")],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }