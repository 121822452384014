var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "site-footer print-hidden text-taupe bg-grey-lightest" },
    [
      _c(
        "div",
        { staticClass: "text-black container mx-auto py-16 px-5-safe" },
        [
          _c(
            "div",
            { staticClass: "flex flex-col md:flex-row gap-x-12 gap-y-12" },
            [
              _c("div", { staticClass: "w-full md:w-1/3" }, [
                _c(
                  "a",
                  {
                    staticClass: "footer-logo",
                    attrs: { href: _vm.logo_href }
                  },
                  [
                    _c("img", {
                      staticClass: "max-h-10",
                      attrs: { src: _vm.logo_src }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "contact-details lg:text-lg leading-loose mt-8 mb-8 text-left"
                  },
                  [_vm._t("contact-details")],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "contact-details lg:text-lg leading-loose mt-8 text-left"
                  },
                  [
                    _vm._l(_vm.social_icons, function(icon, i) {
                      return _c("social-icon", {
                        key: "desktop-mobile-" + i,
                        attrs: {
                          url: icon.url,
                          type: icon.website_value,
                          class_names: [
                            "hidden lg:inline-block text-black tr-color hover:text-brown-lightest",
                            i > 0 ? "ml-2 lg:ml-4" : ""
                          ],
                          icon_size: "28"
                        }
                      })
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.social_icons, function(icon, i) {
                      return _c("social-icon", {
                        key: "social-mobile-" + i,
                        attrs: {
                          url: icon.url,
                          type: icon.website_value,
                          class_names: [
                            "inline-block lg:hidden text-black tr-color hover:text-brown-lightest",
                            i > 0 ? "ml-2 lg:ml-4" : ""
                          ],
                          icon_size: "24"
                        }
                      })
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "w-full md:w-2/3 text-center md:text-left flex flex-col md:flex-row flex-wrap gap-x-12 gap-y-8 md:gap-y-12"
                },
                _vm._l(_vm.footer_content.columns, function(column, i) {
                  return _c(
                    "div",
                    {
                      key: "nav-column-" + i,
                      staticClass:
                        "text-left flex-1 flex flex-col gap-y-4 md:gap-y-8"
                    },
                    [
                      _c(
                        "base-heading",
                        {
                          attrs: { size: "h3", uppercase: true, color: "black" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(column.headingText) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "nav",
                        {
                          staticClass:
                            "footer-nav lg:text-lg leading-tight text-black"
                        },
                        [
                          _c(
                            "ul",
                            _vm._l(column.navigationItems, function(item, i) {
                              return _c(
                                "li",
                                { key: "nav-link-" + i, staticClass: "my-3" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "inline-block relative tr-color hover:underline hover:text-brown-lightest",
                                      attrs: {
                                        href: item.url,
                                        target: item.target
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.text)
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "container mx-auto py-8" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col-reverse md:flex-row md:justify-between gap-x-12 gap-y-8"
              },
              [
                _c("div", [_c("p", [_vm._t("footer-footnote")], 2)]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-1 flex flex-col md:flex-row md:justify-end gap-x-8 gap-y-8"
                  },
                  _vm._l(_vm.footer_content.supplementaryLinks, function(
                    link,
                    i
                  ) {
                    return _c(
                      "a",
                      {
                        key: "supplementary-link-" + i,
                        attrs: { href: link.url, target: link.target }
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(link.text) + "\n          "
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }