<template>
  <div :class="['text-center rounded rounded-md', !block.isFirst ? 'mt-6' : '']" v-show="block.src !== ''">
    <base-image :src="block.src" :retina_src="block.retinaSrc" :alt="block.alt" class_names="mx-auto"></base-image>
  </div>
</template>

<script>
export default {
  props: ["block"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../../components/BaseImage.vue")
  }
};
</script>
