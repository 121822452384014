<template>
    <section class="block comparison-chart-three-column white-block">
        <div :class="[
                include_container ? 'container mx-auto px-5-safe' : 'full-container',
                'pt-10 pb-14 lg:py-20'
             ]">
            <base-heading v-if="data.heading" size="h1" color="brown">
                {{ data.heading}}
            </base-heading>
            <div class="mt-8 text-base sm:text-lg leading-compact sm:leading-normal">
                <div class="mb-3 sm:hidden">
                    <swipe-table-indicator></swipe-table-indicator>
                </div>
                <div class="table-wrapper min-sm">
                    <table class="w-full">

                        <!-- top row -->
                        <thead>
                            <tr>
                                <td class="c1">&nbsp;</td>
                                <td v-for="(header, h) in data.columnHeaders"
                                    :key="h"
                                    :class="[
                                        'c2 bg-brown-lighter text-white p-3 sm:p-5',
                                        'border-t border-r border-l border-brown'
                                    ]">
                                    <strong class="text-lg md:text-xl lg:text-2xl">{{ header.heading }}</strong>
                                    <div v-if="header.subheading">
                                        {{ header.subheading }}
                                    </div>
                                </td>
                            </tr>
                        </thead>

                        <tbody>

                            <!-- data rows -->
                            <tr v-for="(row, r) in data.rows"
                                :key="r">
                                <td class="c1 text-lg py-5 pr-5 border-t border-r border-brown">
                                    <strong class="text-lg md:text-xl lg:text-2xl">{{ row.rowHeading }}</strong>
                                    <div v-if="row.rowSubheading" class="mt-1">
                                        {{ row.rowSubheading }}
                                    </div>
                                </td>
                                <td v-for="n in data.columnHeaders.length"
                                    :key="n"
                                    class="c2 text-center p-3 sm:p-5 align-middle border-t border-r border-brown">
                                    <base-image
                                        v-if="row.valuesCheckmarks[n]"
                                        src="/img/chart-checkmark.png"
                                        retina_src="/img/chart-checkmark@2x.png"
                                    ></base-image>
                                    <div v-if="row.valuesText"
                                         class="mt-2"
                                         v-html="row.valuesText[n]">
                                    </div>
                                </td>
                            </tr>

                            <!-- buttons -->
                            <tr>
                                <td class="c1 border-t border-r border-brown">&nbsp;</td>
                                <td v-for="b in data.columnHeaders.length"
                                    :key="b"
                                    class="c2 p-3 sm:p-5 border-t border-r border-brown">
                                    <div v-if="data.buttons[b]">
                                        <base-button
                                            :url="data.buttons[b].url"
                                            :label="data.buttons[b].text"
                                            color="red"
                                            class_names="w-full text-center"
                                            :target="data.buttons[b].target"
                                        ></base-button>
                                    </div>
                                </td>
                            </tr>
                            <!-- column footers -->
                            <tr>
                                <td class="c1">&nbsp;</td>
                                <td v-for="f in data.columnHeaders.length"
                                    :key="f"
                                    class="c2 px-3 sm:px-5 text-base text-center align-text-top">
                                    <span v-if="data.footers[f]" v-html="data.footers[f]"></span>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  // @group Blocks
  export default {
    props: ["data", "include_container"],
    components: {
      BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
      SwipeTableIndicator: () => import(/* webpackChunkName: "swipe-table-indicator" */ "../components/SwipeTableIndicator.vue")
    },
  };
</script>

<style scoped>
.c1 {
    width: 31%;
}
.c2 {
    width: 23%;
}
</style>
