<template>
  <div class="add-to-cart-overlay fixed inset-0 overlay shadow-md text-black flex justify-end">
    <div class="overlay-content absolute top-0 bottom-0 right-0 overflow-scroll w-full md:w-2/3 xl:w-2/5 border-l-1 bg-grey-light transition transform">
      <header class="block relative py-6 px-5 border-b border-brown">
        <div class="uppercase font-bold text-lg">Your Cart</div>
        <button @click="hideOverlay" class="text-black absolute right-0 centered-y pr-5">
          <base-icon icon-name="x" width="16" height="16" viewbox="0 0 23 23">
            <icon-x></icon-x>
          </base-icon>
        </button>
      </header>
      <div class="pt-4 pb-5 px-5">
        <div class="flex">
          <div class="w-1/3 pr-4">
            <base-image
              :disable_lazy="true"
              :src="$parent.$parent.product_thumbnail_src"
              :retina_src="$parent.$parent.product_thumbnail_retina_src"
            ></base-image>
          </div>
          <div class="w-2/3 pt-1">
            <div class="font-bold text-lg" v-html="$parent.$parent.product_name"></div>
            <div class="text-xs mt-1">Product Code: {{ $parent.$parent.product_code }}</div>
            <div class="mt-3 pt-3 flex flex-row gap-x-4 w-full">
              <div class="flex flex-col gap-y-4 leading-normal justify-between">
                <div class="uppercase">{{ $parent.$parent.product.stock }}</div>
                <div class="flex items-end">
                  <div v-show="addedToCartUnitPrice" class="text-2xl md:text-3xl font-bold">
                    {{ $root.formattedPrice(addedToCartUnitPrice) }}
                  </div>
                  <div class="text-sm font-bold ml-1 mb-1" v-show="addedToCartUnitPrice">{{ $root.formattedCurrency() }}</div>
                </div>
              </div>
              <div v-if="addedToCartQty" class="flex flex-col justify-between gap-y-4">
                <span>Qty:</span>
                <span class="bg-white px-6 py-3 rounded">{{ addedToCartQty }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-14 md:mt-10"
          v-if="$parent.$parent.essential_products_heading !== '' || $parent.$parent.essential_products_text !== ''"
        >
          <div class="text-lg lg:text-2xl font-bold" v-html="$parent.$parent.essential_products_heading"></div>
          <div
            :class="[$parent.$parent.essential_products_heading !== '' ? 'mt-1' : '']"
            v-html="$parent.$parent.essential_products_text"
          ></div>
        </div>
      </div>
      <add-to-cart-box-essential-products
        v-if="$parent.$parent.essential_products_heading !== '' || $parent.$parent.essential_products_text !== ''"
      ></add-to-cart-box-essential-products>
    </div>
    <div @click="hideOverlay" class="bg-grey-darker inset-0 w-full h-full opacity-75"></div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { gaRemoveFromCart } from "../../../js/modules/wa-analytics";
import VueAxios from "../../../js/modules/vue-axios";

export default {
  data: function() {
    return {
      products: this.$parent.$parent.essential_products,
      isDeleting: false
    };
  },
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../../components/BaseButton.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../../components/BaseImage.vue"),
    AddToCartBoxEssentialProducts: () =>
      import(/* webpackChunkName: "add-to-cart-box-essential-products" */ "./AddToCartBoxEssentialProducts.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../../components/BaseIcon.vue"),
    IconX: () => import(/* webpackChunkName: "icon-x" */ "../../icons/IconX.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../../icons/IconLoading.vue")
  },
  computed: {
    addedToCartQty() {
      if (!this.$root.cart) {
        return null;
      }
      let items = this.$root.cart.items ?? null;
      if (!items || items.length === 0) {
        return null;
      }
      const qty = items[`${this.$parent.$parent.product_code}`]?.qty ?? null;

      return qty;
    },
    addedToCartUnitPrice() {
      if (!this.$root.cart) {
        return null;
      }
      let items = this.$root.cart.items ?? null;
      if (!items || items.length === 0) {
        return null;
      }
      return items[`${this.$parent.$parent.product_code}`]?.unit ?? null;
    },
  },
  methods: {
    hideOverlay() {
      this.$parent.showOverlay = false;
    }
  },
  directives: {
    ClickOutside
  }
};
</script>

<style scoped>
.overlay {
  z-index: 110000;
}
.overlay-content {
  z-index: 110001;
}
</style>
