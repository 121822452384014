<template>
  <div :class="['input-product-quantity', class_names]">
    <div class="relative h-full w-full">
      <input
        type="number"
        aria-label="Quantity"
        pattern="[0-9]"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        step="1"
        v-model="localQty"
        min="0"
        v-on:keyup.enter="keyEnter()"
        :class="[
          'w-full text-xl font-bold rounded-lg h-full text-center bg-white leading-tight',
          is_loading ? 'text-white' : is_disabled ? 'text-grey' : 'text-black',
          border_color !== undefined
            ? 'border-3 ' + (is_disabled || is_loading ? 'border-grey-light' : 'border-' + border_color)
            : ''
        ]"
        style="height: 100%"
      />
      <base-icon icon-name="loading" width="24" height="24" viewbox="0 0 32 32" class="centered text-grey" v-show="is_loading">
        <icon-loading></icon-loading>
      </base-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "bg_color", "border_color", "is_disabled", "is_loading", "allow_enter_key_submit", "product_code", "class_names"],
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../icons/IconLoading.vue")
  },
  methods: {
    keyEnter() {
      if (this.allow_enter_key_submit) {
        this.$emit("quantitychanged", this.product_code);
      }
    }
  },
  computed: {
    localQty: {
      get() {
        return this.value;
      },
      set(localQty) {
        this.$emit("input", localQty);
      }
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield;
  padding-top: 6px;
  padding-bottom: 6px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

