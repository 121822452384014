<template>
  <div class="product-detail-sections">
    <div
      v-for="(section, i) in $parent.detailSections"
      :key="i"
      :data-section-id="i"
      :id="section.heading.replace(/\s+/g, '-').toLowerCase()"
      :class="['product-detail-section mb-6', section.active ? 'opened' : 'closed']"
    >
      <button
        type="button"
        @click="section.active = !section.active"
        :class="[
          'relative text-2xl xl:text-3xl font-bold tr-bg tr-color block border-b-2 border-brown w-full text-left pr-11 py-2 text-brown',
          section.active ? 'active' : '',
        ]"
      >
        {{ section.heading }}
        <span class="arrow absolute centered-y right-0 mr-6"></span>
      </button>
      <slide-up-down :active="section.active" :duration="350" class="px-2">
        <div class="pr-4 py-8 xl:pr-6 xl:py-10">
          <div v-for="(block, b) in section.blocks" :key="b">
            <component :is="'ProductDetail' + capitalizeFirstLetter(block.type)" :block="block"></component>
          </div>
        </div>
      </slide-up-down>
    </div>
    <div id="Accessories" :class="['product-detail-section mb-6', active ? 'opened' : 'closed']" v-if="$parent.accessories && $parent.accessories.length > 0">
      <button
          type="button"
          @click="active = !active"
          :class="[
          'relative text-2xl xl:text-3xl font-bold tr-bg tr-color block border-b-2 border-brown w-full text-left pr-11 py-2',
          active ? 'active' : ''
        ]"
      >
        Accessories
        <span class="arrow absolute centered-y right-0 mr-6"></span>
      </button>
      <slide-up-down :active="active" :duration="350" class="px-2">
        <div class="pr-4 xl:pr-6 py-8 xl:py-10">
          <div v-for="(accessory, i) in $parent.accessories" :key="i" :data-section-id="i">
            <div
                :class="[
                  'product-side-by-side-text-and-image border-t border-brown pt-6 mb-4 md:flex',
                  i === 0 ? 'mt-6' : '',
                ]"
            >
              <div class="col-1 mb-6 w-full sm:w-1/4 md:mb-0">
                <base-image v-if="accessory.image.src" :src="accessory.image.src" :alt="accessory.image.alt ? accessory.image.alt : accessory.image.title" class_names="mx-auto"></base-image>
                <!-- (left in to look into transforms in the future, non-transformed image size is controlled by tailwind classes right now)
                    <base-image v-else :src="accessory.mainProductImage.url" :retina_src="accessory.mainProductImage.url" :alt="accessory.mainProductImage.alt" class_names="mx-auto"></base-image> -->
              </div>
              <div class="col-2 md:flex md:pr-6 w-full sm:w-3/4 lg:pr-8 xl:pr-10">
                <div class='flex-auto'>
                  <base-heading size="h2" color="brown" v-if="accessory.product_title !== ''" v-text="accessory.product_title"></base-heading>
                  <base-rich-text :class_names="accessory.product_title !== '' ? 'mt-2' : ''" v-text="accessory.product_description"></base-rich-text>
                </div>

                <div v-if='accessory' class='flex-none md:w-64 mb-6 md:ml-6 lg:ml-8 xl:ml-10 mt-10 md:mt-0'>
                  <add-to-cart-simple :product="accessory.product"></add-to-cart-simple>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slide-up-down>
    </div>
  </div>
</template>

<script>
import HelpersMixin from "../../mixins/Helpers";

export default {
  mixins: [HelpersMixin],
  components: {
    ProductDetailAnchor: () => import(/* webpackChunkName: "product-detail-anchor" */ "./ProductDetailAnchor.vue"),
    ProductDetailHeading: () => import(/* webpackChunkName: "product-detail-heading" */ "./ProductDetailHeading.vue"),
    ProductDetailRichText: () => import(/* webpackChunkName: "product-detail-rich-text" */ "./ProductDetailRichText.vue"),
    ProductDetailImage: () => import(/* webpackChunkName: "product-detail-image" */ "./ProductDetailImage.vue"),
    ProductDetailRawHtml: () => import(/* webpackChunkName: "product-detail-raw-html" */ "./ProductDetailRawHtml.vue"),
    ProductDetailSideBySideTextAndImage: () =>
      import(/* webpackChunkName: "product-detail-side-by-side-text-and-image" */ "./ProductDetailSideBySideTextAndImage.vue"),
    ProductDetailTable: () => import(/* webpackChunkName: "product-detail-table" */ "./ProductDetailTable.vue"),
    ProductDetailImportantNotice: () =>
      import(/* webpackChunkName: "product-detail-important-notice" */ "./ProductDetailImportantNotice.vue"),
    ProductDetailSpecificationsTable: () =>
      import(/* webpackChunkName: "product-detail-specifications-table" */ "./ProductDetailSpecificationsTable.vue"),
    ProductDetailThreeColumns: () => import(/* webpackChunkName: "product-detail-three-columns" */ "./ProductDetailThreeColumns.vue"),
    ProductDetailTwoColumns: () => import(/* webpackChunkName: "product-detail-two-columns" */ "./ProductDetailTwoColumns.vue"),
    ProductDetailVideo: () => import(/* webpackChunkName: "product-detail-video" */ "./ProductDetailVideo.vue"),
    AddToCartSimple: () => import(/*  */ "../add-to-cart/AddToCartSimple.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../../components/BaseRichText.vue"),
  },
  props: ["active"],
  mounted() {
    let self = this;

    // Open detail section based on CMS settings
    self.$root.$on("open-product-detail-section", function(sectionId) {
      var section = self.$parent.detailSections[sectionId] || null;
      if (section) {
        section.active = true;
      }
    });

    // Open detail section based on URL hash
    let hash = window.location.hash;
    hash = hash.substring(1);
    const el = document.getElementById(hash);

    if (!el) return;

    self.$parent.detailSections.forEach(detailSection => {
      detailSection.active = false;
    });

    self.$parent.detailSections[el.dataset.sectionId].active = true;
  }
};
</script>

<style scoped>
.bg-c {
  background: #e8e8e5;
}
button {
  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
  }
  &.active {
    .arrow {
      border-width: 0 9px 8px 9px;
      border-color: transparent transparent #4b3d2a transparent;
    }
  }
  &:not(.active) {
    .arrow {
      border-width: 8px 9px 0 9px;
      border-color: #4b3d2a transparent transparent transparent;
    }
  }
}
</style>
