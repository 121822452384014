var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full flex flex-col gap-y-8" },
    [
      _c(
        "add-to-cart-box-price-tiers-flyout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasPriceOverride,
              expression: "!hasPriceOverride"
            }
          ]
        },
        [
          _c(
            "template",
            { slot: "bulk_prices_text" },
            [_vm._t("bulk_prices_text")],
            2
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex flex-row gap-x-4" },
        [
          _c(
            "div",
            {
              class:
                (!_vm.showContactSales && !_vm.hasPriceOverride
                  ? "w-1/2"
                  : "") + " flex flex-col gap-y-4 justify-between"
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.hasPriceOverride,
                    expression: "!hasPriceOverride"
                  }
                ],
                staticClass: "stock text-base lg:text-lg uppercase",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$parent.product.stock
                      ? _vm.$parent.product.stock
                      : "In Stock"
                  )
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-row items-end" }, [
                _c("span", {
                  staticClass: "font-bold text-3xl lg:text-4xl",
                  domProps: { innerHTML: _vm._s(_vm.listPrice) }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "text-xs lg:text-sm font-bold ml-1 mb-1",
                  domProps: { innerHTML: _vm._s(_vm.currency) }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _vm.showContactSales || _vm.hasPriceOverride
            ? _c("div", { staticClass: "flex flex-col" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "w-full text-center uppercase text-xl font-bold bg-red rounded text-white py-3 px-6",
                    attrs: { href: "/sales" }
                  },
                  [_vm._v("\n        Contact Sales\n      ")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "italic mt-1" }, [
                  _vm._v("For additional details, contact our Sales team.")
                ])
              ])
            : [
                _c(
                  "div",
                  { staticClass: "flex flex-col gap-y-4 justify-end w-1/6" },
                  [
                    _c("span", [_vm._v("Qty:")]),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quantity,
                            expression: "quantity"
                          }
                        ],
                        staticClass:
                          "px-2 inline-block w-16 h-12 bg-white text-black text-xl font-bold rounded-lg text-center",
                        attrs: {
                          type: "number",
                          pattern: "[0-9]",
                          onkeypress:
                            "return event.charCode >= 48 && event.charCode <= 57",
                          step: "1",
                          min: "0",
                          id: "quantity",
                          name: ["quantity_" + _vm.$parent.product_code],
                          value: "1"
                        },
                        domProps: { value: _vm.quantity },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.quantity = $event.target.value
                          }
                        }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-grow flex flex-col gap-y-4 justify-end w-1/2"
                  },
                  [
                    _c(
                      "button",
                      {
                        class: [
                          "loading-btn bg-red text-base xl:text-xl inline-block font-bold rounded text-center h-12 w-full uppercase",
                          _vm.isLoading || _vm.quantity < 1
                            ? "text-grey"
                            : "text-white tr-color tr-bg hover:bg-red-hover",
                          _vm.isLoading ? "pl-8" : ""
                        ],
                        attrs: {
                          type: "submit",
                          disabled: _vm.isLoading || _vm.quantity < 1
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.addToCart()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "inline" }, [
                          _vm._v("Add to Cart")
                        ]),
                        _vm._v(" "),
                        _vm.isLoading
                          ? _c(
                              "base-icon",
                              {
                                staticClass: "loading ml-1",
                                attrs: {
                                  "icon-name": "loading",
                                  width: "12",
                                  height: "12",
                                  viewbox: "0 0 32 32"
                                }
                              },
                              [_c("icon-loading")],
                              1
                            )
                          : _c(
                              "base-icon",
                              {
                                staticClass: "icon-arrow-outward ml-1",
                                attrs: {
                                  "icon-name": "icon-arrow-outward",
                                  width: "10",
                                  height: "10",
                                  viewbox: "0 0 10 10"
                                }
                              },
                              [_c("icon-arrow-outward")],
                              1
                            )
                      ],
                      1
                    )
                  ]
                )
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$parent.has_price_footnote,
              expression: "$parent.has_price_footnote"
            }
          ],
          staticClass: "text-base leading-normal"
        },
        [_vm._t("price_footnote")],
        2
      ),
      _vm._v(" "),
      _vm.showOverlay ? _c("add-to-cart-box-overlay") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }