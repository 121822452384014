<template>
  <div v-if='success' class='rich-text'>
    <p>
      Thank you for contacting Wildlife Acoustics Sales! A support case number has been created and a confirmation email has been sent to <span v-text="email"></span>. Your message has been submitted and we will try to respond within 1-2 business days.
    </p>

    <p>
      Many questions can be answered by visiting our <a href='/shop'>online store</a> or by our <a href='/resources/faqs?c=orders,returns,shipping'>Shipping/Order/Returns FAQs</a>.
    </p>

    <p>
      For international inquiries, we encourage you to contact a local reseller. Direct pricing from Wildlife Acoustics will be in US dollars and will not include VAT, import fees, or duties. Your local reseller will have the most accurate and affordable pricing for you. A list of authorized resellers may be found <a href='/resellers'>here</a>.
    </p>

    <p>
      If your question is urgent, please call us at 1 (978) 369-5225 and choose option 1 for Sales. Our standard hours are 9am - 5pm Monday - Friday Eastern (Boston) time excluding U.S. holidays. When calling outside of business hours, please leave a message and we will get back to you as quickly as we can.
    </p>

    <p>
      Thank you again and we will respond shortly.
      </p>

      <p>
        -The Wildlife Acoustics Sales Team
      </p>
  </div>

  <div v-else>
    <div class='text-left text-lg'>
      <div class='lg:flex'>
        <div class='lg:w-1/2 lg:pr-10'>
          <h1 class='mb-8 font-bold'>Contact Information</h1>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='first_name'>Given Name or Nickname *</label>
            <form-input
              input_type='text'
              name='first_name'
              v-model='first_name'
              :class='{ "border-red": errors.includes("first_name") }'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='last_name'>Surname *</label>
            <form-input
              type='text'
              name='last_name'
              :class='{ "border-red": errors.includes("last_name") }'
              v-model='last_name'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='email'>Email Address *</label>
            <form-input
              type='text'
              name='email'
              :class='{ "border-red": errors.includes("email") }'
              v-model='email'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='job_title'>Job Title</label>
            <form-input
              type='text'
              name='job_title'
              v-model='job_title'
              :class='{ "border-red": errors.includes("job_title") }'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='country'>Country *</label>
            <form-select
              name='country'
              v-model='country'
              :class_names='[ errors.includes("country") ? "border-red" : "" ]'
              :options='country_options'
              :disabled='is_loading'
              />
          </div>

          <div class='mb-5'>
            <form-checkbox
              label='Are you requesting a quote? If so, the following fields are required.'
              name='quote'
              v-model='quote'
              :disabled='is_loading'>
            </form-checkbox>
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='organization_name'>Organization Name<span v-show='quote'> *</span></label>
            <form-input
              type='text'
              name='organization_name'
              v-model='organization_name'
              :class='{ "border-red": errors.includes("organization_name") }'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='organization_type'>Organization Type<span v-show='quote'> *</span></label>
            <form-select
              name='organization_type'
              v-model='organization_type'
              :options='organization_types'
              :class_names='[ errors.includes("organization_type") ? "border-red" : "" ]'
              :disabled='is_loading'>
            </form-select>
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='phone_number'>Phone Number<span v-show='quote'> *</span></label>
            <form-input
              type='text'
              name='phone'
              :class='{ "border-red": errors.includes("phone") }'
              v-model='phone'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='address_1'>Address 1<span v-show='quote'> *</span></label>
            <form-input
              type='text'
              name='address_1'
              :class='{ "border-red": errors.includes("address_1") }'
              v-model='address_1'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='address_2'>Address 2</label>
            <form-input
              type='text'
              name='address_2'
              :class='{ "border-red": errors.includes("address_2") }'
              v-model='address_2'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='city'>City<span v-show='quote'> *</span></label>
            <form-input
              type='text'
              name='city'
              :class='{ "border-red": errors.includes("city") }'
              v-model='city'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='state'>{{ state_label }}<span v-show='quote'> *</span></label>

            <div v-if='has_states' :class="['input-select-wrapper relative', is_loading ? 'disabled' : '']">
              <template v-if="country === 'UNITED KINGDOM'">
                <select
                  v-model="state"
                  name="state"
                  :disabled="is_loading"
                  :class="['input-select', errors.includes('state') ? 'border-red' : '']"
                >
                  <optgroup v-for="(states, group) in states_for_country" :label="group" :key="'state-group-' + group">
                    <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                  </optgroup>
                </select>
              </template>

              <template v-else>
                <select
                  v-model="state"
                  name="state"
                  :disabled="is_loading"
                  :class="['input-select', errors.includes('state') ? 'border-red' : '']"
                >
                  <option v-for="state in states_for_country" :key="state" :value="state">{{ state }}</option>
                </select>
              </template>
            </div>

            <form-input
              v-else
              type='text'
              name='state'
              v-model='state'
              :class='{ "border-red": errors.includes("state") }'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='postal_code'>Postal Code<span v-show='quote'> *</span></label>
            <form-input
              type='text'
              name='postal_code'
              :class='{ "border-red": errors.includes("postal_code") }'
              v-model='postal_code'
              :disabled='is_loading'
              />
          </div>
        </div>

        <div class='lg:w-1/2 lg:pl-10'>
          <h1 class='my-8 lg:mt-0 font-bold'>Project Information</h1>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='subject'>Subject of Inquiry</label>
            <form-input
              type='text'
              name='subject'
              v-model='subject'
              :class='{ "border-red": errors.includes("subject") }'
              :disabled='is_loading'
              />
          </div>

          <div class='mb-5'>
            <label for='message'>
              Tell us about your project and how we can help! For faster service, if
              this is a quote request, please let us know the product(s) and quantities
              if you know them. *
            </label>
            <textarea
              type='text'
              name='message'
              rows='4'
              class='input-text'
              style='font-family: inherit;'
              v-model='message'
              :class='{ "border-red": errors.includes("message") }'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='project_type'>Project Type</label>
            <form-select
              name='project_type'
              v-model='project_type'
              multiple='true'
              :rows='Object.keys(project_types).length'
              :options='project_types'
              :class_names='[ errors.includes("project_type") ? "border-red" : "" ]'
              :disabled='is_loading'>
            </form-select>
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='study_subject'>Study Subject</label>
            <form-select
              name='study_subject'
              v-model='study_subject'
              multiple='true'
              :rows='Object.keys(study_subjects).length'
              :options='study_subjects'
              :class_names='[ errors.includes("study_subject") ? "border-red" : "" ]'
              :disabled='is_loading'>
            </form-select>
          </div>

          <div class='sm:w-p60 lg:w-full mb-5' v-show='study_subject.includes("Other")'>
            <label for='study_subject_other'>Study Subject (Other)</label>
            <form-input
              type='text'
              name='study_subject_other'
              v-model='study_subject_other'
              :class='{ "border-red": errors.includes("study_subject_other") }'
              :disabled='is_loading'
              />
          </div>

          <div class='sm:w-p60 lg:w-full mb-5'>
            <label for='deployment_location'>Deployment Location</label>
            <form-input
              type='text'
              name='deployment_location'
              v-model='deployment_location'
              :class='{ "border-red": errors.includes("deployment_location") }'
              :disabled='is_loading'
              />
          </div>
        </div>
      </div>

      <div class='mb-5 lg:mt-5'>
        <form-checkbox
          label='Subscribe to our mailing list and stay up-to-date on Technical Support Bulletins, Quarterly Newsletters and General Announcements.'
          name='subscribe'
          v-model='subscribe'
          :disabled='is_loading'>
        </form-checkbox>
      </div>

      <div class="mt-4 text-lg leading-normal" v-if="error_message">
        <span class='text-red' v-html="error_message"></span>
      </div>

      <div class="mt-4">
        <button
          type='submit'
          @click.prevent='submit()'
          class="relative bg-red font-bold uppercase rounded border-4 text-xl py-2 tr-color tr-bg hover:bg-white hover:text-black px-24 text-white border-red">
          <base-icon
            icon-name="loading"
            width="28"
            height="28"
            viewbox="0 0 32 32"
            class="loading absolute ml-3 left-0 centered-y"
            v-show="is_loading"
          >
            <icon-loading></icon-loading>
          </base-icon>
          <span class="inline">Submit</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueAxios from "../../js/modules/vue-axios";

export default {
  props: {
    countries: [],
    states: [],
    state_labels: {},
  },

  data: function() {
    return {
      // lookups
      organization_types: {
        'Government': 'Government',
        'For-profit': 'For-profit',
        'University': 'University',
        'Non-profit': 'Non-profit',
        'Individual': 'Individual',
        'K-12': 'K-12',
      },
      project_types: {
        'Biodiversity': 'Biodiversity',
        'Ecoacoustics/Soundscapes': 'Ecoacoustics/Soundscapes',
        'Monitoring to Inform Species Conservation': 'Monitoring to Inform Species Conservation',
        'Environmental Impact Assessments': 'Environmental Impact Assessments',
        'Animal Behavior Studies': 'Animal Behavior Studies',
        'Education and Community Outreach': 'Education and Community Outreach',
      },
      study_subjects: {
        'Bird': 'Bird',
        'Bat': 'Bat',
        'Frog': 'Frog',
        'Primate': 'Primate',
        'Insect': 'Insect',
        'Rodent': 'Rodent',
        'Noise': 'Noise',
        'Other': 'Other',
      },

      // form fields
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      city: "",
      address_1: "",
      address_2: "",
      state: "",
      postal_code: "",
      country: "",
      organization_name: "",
      organization_type: "",
      job_title: "",
      project_type: "",
      study_subject: "",
      study_subject_other: "",
      deployment_location: "",
      quote: false,
      subject: "",
      message: "",
      subscribe: "",

      // state
      success: false,
      is_loading: false,
      errors: [],
      error_message: null,
    }
  },

  computed: {
    country_options: function() {
      if (!this.countries) { return {}; }

      const dict = {};

      for (const country of this.countries) {
        dict[country] = country;
      }

      return dict;
    },

    has_states: function() {
      const states_for_country = this.states_for_country;

      return !!states_for_country;
    },

    states_are_grouped: function() {
      const states_for_country = this.states_for_country;

      if (Array.isArray(states_for_country)) { return false; }  // if the states are a simple list
      if (states_for_country instanceof Object) { return true; }  // if the states are grouped

      return false;
    },

    states_for_country: function() {
      if (!this.country || !this.states) { return false; }

      const states_for_country = this.states[this.country];

      return states_for_country;
    },

    state_label: function() {
      const label = this.state_labels[this.country];

      if (!label) { return 'State/Province'; }

      return label;
    },
  },

  methods: {
    submit() {
      let self = this;

      // check for errors

      self.errors = [];

      if (!self.first_name) { self.errors.push('first_name'); }
      if (!self.last_name) { self.errors.push('last_name'); }
      if (!self.email) { self.errors.push('email'); }
      if (!self.country) { self.errors.push('country'); }
      if (!self.message) { self.errors.push('message'); }

      if (self.quote) {
        if (!self.organization_type) { self.errors.push('organization_type'); }
        if (!self.organization_name) { self.errors.push('organization_name'); }
        if (!self.phone) { self.errors.push('phone'); }
        if (!self.address_1) { self.errors.push('address_1'); }
        if (!self.city) { self.errors.push('city'); }
        if (!self.state) { self.errors.push('state'); }
        if (!self.postal_code) { self.errors.push('postal_code'); }
      }

      if (self.errors.length > 0) {
        self.error_message = 'Some fields are missing data. Please review the errors above.';
        return;
      }

      // upload the data

      self.is_loading = true;

      let formData = new FormData();
      formData.append('action', 'wildlife/netsuite/sales-form');
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append('fname', self.first_name);
      formData.append('lname', self.last_name);
      formData.append('email', self.email);
      formData.append('phone', self.phone);
      formData.append('country', self.country);
      formData.append('addr1', self.address_1);
      formData.append('addr2', self.address_2);
      formData.append('city', self.city);
      formData.append('state', self.state);
      formData.append('zip', self.postal_code);
      formData.append('orgname', self.organization_name);
      formData.append('orgtype', self.organization_type);
      formData.append('jobtitle', self.job_title);
      formData.append('projectType', JSON.stringify(self.project_type));
      formData.append('studySubject', JSON.stringify(self.study_subject));
      formData.append('studySubjectDescription', self.study_subject_other);
      formData.append('deploymentLocation', self.deployment_location);
      formData.append('isQuote', self.quote);
      formData.append('subject', self.subject);
      formData.append('about', self.message);
      formData.append('subscribe', self.subscribe);

      let options = {
        method: 'POST',
        data: formData,
        url: '/'
      };

      VueAxios(options)
        .then(response => {
          let data = response.data;

          if (data.success) {
            self.success = true;
          } else {
            self.error_message = data.message;
          }

          self.is_loading = false;
        })
        .catch(error => {
          self.error_message = 'An error occured. Please try again.';
          self.is_loading = false;
        });
    }
  },

  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    FormCheckbox: () => import(/* webpackChunkName: "form-checkbox" */ "../components/form/FormCheckbox.vue"),
    FormInput: () => import(/* webpackChunkName: "form-input" */ "../components/form/FormInput.vue"),
    FormSelect: () => import(/* webpackChunkName: "form-select" */ "../components/form/FormSelect.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../icons/IconLoading.vue")
  },
};
</script>
