var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "block boxed-large-image-with-buttons-and-text white-block"
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "py-10"
          ]
        },
        [
          _c("div", { staticClass: "relative py-6 lg:py-16" }, [
            _vm.large_heading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "large-heading lg:whitespace-no-wrap lg:absolute lg:bg-white lg:px-8"
                  },
                  [
                    _c(
                      "base-heading",
                      { attrs: { size: "h1", color: "brown" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.large_heading) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.image_src
              ? _c(
                  "div",
                  { staticClass: "mt-6 lg:mt-0" },
                  [
                    _c("base-image", {
                      attrs: {
                        src: _vm.image_src,
                        retina_src: _vm.image_retina_src,
                        srcset_webp: _vm.image_srcset_webp,
                        class_names: "mx-auto",
                        alt: _vm.alt
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.heading || _vm.subheading
              ? _c("div", { staticClass: "md:flex mt-12" }, [
                  _c(
                    "div",
                    { staticClass: "empty-col mb-8 md:mb-0 md:w-1/3" },
                    [_vm._v("\n           \n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "md:w-2/3 md:pl-12" },
                    [
                      _c(
                        "base-heading",
                        { attrs: { size: "h2", color: "brown" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.heading) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "base-heading",
                        {
                          attrs: {
                            size: "h4",
                            color: "green",
                            uppercase: true,
                            class_names: "mt-3"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.subheading) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$slots.buttons
              ? _c("div", { staticClass: "md:flex mt-5" }, [
                  _c(
                    "div",
                    { staticClass: "mb-4 md:mb-0 pt-1 md:w-1/3" },
                    [_vm._t("buttons")],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "md:w-2/3 md:pl-12" },
                    [
                      _c(
                        "base-rich-text",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.has_text,
                              expression: "has_text"
                            }
                          ],
                          attrs: { class_names: "heading ? 'mt-3':''" }
                        },
                        [[_vm._t("text")]],
                        2
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }