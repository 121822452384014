<template>
  <div
    :class="[
      'product-side-by-side-text-and-image md:flex',
      block.position === 'right' ? 'flex-row-reverse' : '',
      !block.isFirst ? 'mt-6' : '',
      block.previousBlock === 'sideBySideTextAndImage' ? 'border-t border-brown pt-6' : ''
    ]"
  >
    <div class="col-1 mb-6 md:mb-0">
      <base-image v-if="block.product" :src="block.product.main_product_image" class_names="mx-auto rounded rounded-lg"></base-image>
      <base-image v-else :src="block.src" :retina_src="block.retinaSrc" :alt="block.alt" class_names="mx-auto rounded rounded-lg"></base-image>
    </div>
    <div :class="['col-2', block.position === 'right' ? 'md:pr-6 lg:pr-8 xl:pr-10' : 'md:pl-6 lg:pl-8 xl:pl-10', 'md:flex']">
      <div class='flex-auto'>
        <base-heading size="h2" color="brown" v-show="block.text !== ''">
          {{ block.heading }}
        </base-heading>
        <base-rich-text :class_names="block.heading !== '' ? 'mt-2' : ''" v-html="block.text"></base-rich-text>
      </div>

      <div v-if='block.product' class='flex-none md:w-64 md:ml-6 lg:ml-8 xl:ml-10 mt-10 md:mt-0'>
        <add-to-cart-simple :product="block.product"></add-to-cart-simple>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["block"],
  components: {
    AddToCartSimple: () => import(/*  */ "../add-to-cart/AddToCartSimple.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../../components/BaseRichText.vue"),
  }
};
</script>

<style scoped>
@media (min-width: 768px) {
  .col-1 {
    width: 200px;
  }

  .col-2 {
    width: calc(100% - 200px);
  }
}
</style>
