<template>
  <div class="add-to-cart-tiers-flyout tiers" v-if="priceTiers.length > 1">
    <div class="bg-brown-lighter shadow-md py-1 px-3 text-base xl:text-lg">
      <button @click="showTiers = !showTiers" type="button" class="block relative w-full py-1 text-left">
        <span class="text text-white">Quantity Discount</span>
        <span :class="['arrow block absolute right-0 centered-y', showTiers ? 'down' : 'up']"></span>
      </button>
      <slide-up-down :active="showTiers" :duration="350">
        <div class="flex flex-wrap text-brown">
          <div v-for="item in priceTiers" class="w-1/2 flex pt-4">
            <div class="w-p40 text-center">{{ item.label }}:</div>
            <div class="w-p60">
              {{ $root.formattedPrice(item.price) }} 
              &nbsp;
              <span class="text-xs">{{ $root.formattedCurrency() }}</span>
            </div>
          </div>
        </div>
        <div class="mt-6 mb-2 text-white text-base">
          <slot name="bulk_prices_text"></slot>
        </div>
      </slide-up-down>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      priceTiers: this.$parent.$parent.price_tiers,
      showTiers: false
    };
  }
};
</script>

<style scoped>
button:hover {
  .text {
    text-decoration: underline;
  }
}
.tiers {
  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    &.down {
      border-width: 8px 9px 0 9px;
      border-color: #fff transparent transparent transparent;
    }
    &.up {
      border-width: 0 9px 8px 9px;
      border-color: transparent transparent #fff transparent;
    }
  }
}
</style>
