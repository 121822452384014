var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col gap-y-8" }, [
    _c(
      "div",
      { staticClass: "relative" },
      [
        _c("base-image", {
          attrs: {
            disable_lazy: true,
            src: _vm.selectedImage.src,
            retina_src: _vm.selectedImage.retinaSrc,
            alt: _vm.selectedImage.alt,
            class_names: "w-full h-96 lg:h-148 object-cover rounded-lg"
          }
        }),
        _vm._v(" "),
        _vm.product_versions.length > 0
          ? _c(
              "div",
              { staticClass: "absolute flex flex-col gap-y-2 versions" },
              [
                _c(
                  _vm.product_versions.length > 1 ? "button" : "div",
                  {
                    tag: "component",
                    class: [
                      "bg-black opacity-75 text-white text-base",
                      _vm.product_versions.length > 1 ? "cursor-pointer" : "",
                      _vm.showVersions ? "active" : ""
                    ],
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.toggleVersions($event)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "border-b-4 border-white px-6 py-1 w-full flex justify-between items-center gap-x-4"
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.versionsHeading))]),
                        _vm._v(" "),
                        _vm.product_versions.length > 1
                          ? _c("span", { staticClass: "arrow text-white" })
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "slide-up-down",
                  {
                    attrs: {
                      active:
                        _vm.product_versions.length === 1 || _vm.showVersions,
                      duration: 100
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-col gap-y-2" },
                      _vm._l(_vm.product_versions, function(version) {
                        return _c(
                          "a",
                          {
                            key: version.id,
                            staticClass:
                              "px-6 py-2 bg-black opacity-75 text-white hover:opacity-100 rounded-full text-xl flex flex-row items-center justify-between gap-x-2 cursor-pointer",
                            attrs: { href: version.url, target: "_blank" }
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(version.product_version_name))
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "border border-white rounded-full flex justify-center items-center",
                                staticStyle: {
                                  width: "1.75rem",
                                  height: "1.75rem"
                                }
                              },
                              [
                                _c(
                                  "base-icon",
                                  {
                                    attrs: {
                                      "icon-name":
                                        "product-version-" + version.id,
                                      width: "7",
                                      height: "7",
                                      viewbox: "0 0 10 10"
                                    }
                                  },
                                  [_c("icon-arrow-outward")],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.images.length > 1
      ? _c(
          "div",
          { staticClass: "overflow-x-auto whitespace-no-wrap" },
          _vm._l(_vm.thumbnails, function(thumbnail, index) {
            return _c(
              "button",
              {
                key: index,
                staticClass: "w-28 h-28 px-1",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.setSelectedImage(index)
                  }
                }
              },
              [
                _c("base-image", {
                  attrs: {
                    disable_lazy: true,
                    src: thumbnail.src,
                    retina_src: thumbnail.retinaSrc,
                    alt: thumbnail.alt,
                    class_names: "rounded-lg w-full"
                  }
                })
              ],
              1
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }