var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "add-to-cart-overlay fixed inset-0 overlay shadow-md text-black flex justify-end"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "overlay-content absolute top-0 bottom-0 right-0 overflow-scroll w-full md:w-2/3 xl:w-2/5 border-l-1 bg-grey-light transition transform"
        },
        [
          _c(
            "header",
            { staticClass: "block relative py-6 px-5 border-b border-brown" },
            [
              _c("div", { staticClass: "uppercase font-bold text-lg" }, [
                _vm._v("Your Cart")
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "text-black absolute right-0 centered-y pr-5",
                  on: { click: _vm.hideOverlay }
                },
                [
                  _c(
                    "base-icon",
                    {
                      attrs: {
                        "icon-name": "x",
                        width: "16",
                        height: "16",
                        viewbox: "0 0 23 23"
                      }
                    },
                    [_c("icon-x")],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pt-4 pb-5 px-5" }, [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                { staticClass: "w-1/3 pr-4" },
                [
                  _c("base-image", {
                    attrs: {
                      disable_lazy: true,
                      src: _vm.$parent.$parent.product_thumbnail_src,
                      retina_src:
                        _vm.$parent.$parent.product_thumbnail_retina_src
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-2/3 pt-1" }, [
                _c("div", {
                  staticClass: "font-bold text-lg",
                  domProps: {
                    innerHTML: _vm._s(_vm.$parent.$parent.product_name)
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "text-xs mt-1" }, [
                  _vm._v(
                    "Product Code: " + _vm._s(_vm.$parent.$parent.product_code)
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-3 pt-3 flex flex-row gap-x-4 w-full" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-col gap-y-4 leading-normal justify-between"
                      },
                      [
                        _c("div", { staticClass: "uppercase" }, [
                          _vm._v(_vm._s(_vm.$parent.$parent.product.stock))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex items-end" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.addedToCartUnitPrice,
                                  expression: "addedToCartUnitPrice"
                                }
                              ],
                              staticClass: "text-2xl md:text-3xl font-bold"
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$root.formattedPrice(
                                      _vm.addedToCartUnitPrice
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.addedToCartUnitPrice,
                                  expression: "addedToCartUnitPrice"
                                }
                              ],
                              staticClass: "text-sm font-bold ml-1 mb-1"
                            },
                            [_vm._v(_vm._s(_vm.$root.formattedCurrency()))]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.addedToCartQty
                      ? _c(
                          "div",
                          {
                            staticClass: "flex flex-col justify-between gap-y-4"
                          },
                          [
                            _c("span", [_vm._v("Qty:")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "bg-white px-6 py-3 rounded" },
                              [_vm._v(_vm._s(_vm.addedToCartQty))]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _vm.$parent.$parent.essential_products_heading !== "" ||
            _vm.$parent.$parent.essential_products_text !== ""
              ? _c("div", { staticClass: "mt-14 md:mt-10" }, [
                  _c("div", {
                    staticClass: "text-lg lg:text-2xl font-bold",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$parent.$parent.essential_products_heading
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    class: [
                      _vm.$parent.$parent.essential_products_heading !== ""
                        ? "mt-1"
                        : ""
                    ],
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$parent.$parent.essential_products_text
                      )
                    }
                  })
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.$parent.$parent.essential_products_heading !== "" ||
          _vm.$parent.$parent.essential_products_text !== ""
            ? _c("add-to-cart-box-essential-products")
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "bg-grey-darker inset-0 w-full h-full opacity-75",
        on: { click: _vm.hideOverlay }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }