<template>
  <div class="flex flex-col justify-center gap-y-8 pt-24 pb-24">
    <p class="text-center font-bold text-2xl md:text-4xl lg:text-6xl xl:text-7xl">{{ data.heading }}</p>
    <div class="flex gap-x-8 overflow-x-auto">
        <button v-for="(tab, i) in data.tabs" :key="i" @click.prevent="setCurrentTab(i)" :class="`${currentTabClasses(i)} md:text-lg lg:text-2xl hover:text-grey-dark`">
          {{ tab.heading }}
        </button>
    </div>
    <div class="flex flex-col">
      <p class="uppercase text-sm">{{ currentTabContent.subheading }}</p>
      <div class="pt-2 flex flex-col md:flex-row gap-y-8 gap-x-8">
        <component :is="card.linkUrl ? 'a' : 'div'" v-for="(card, i) in currentTabContent.cards" :key="i" :target="card.linkTarget" :href="card.linkUrl" :class="`${ card.linkUrl ? 'cursor-pointer' : '' } w-full md:w-1/4 tabbed-card group`">
          <div class="flex flex-col">
            <div class="rounded-lg bg-grey-lighter2 h-48 w-full tabbed-card-image flex justify-center items-center">
              <img :srcset="`${card.image.src} 1x, ${card.image.srcRetina} 2x`" :alt="card.image.alt" class="object-fit h-full" />
            </div>
            <p class="text-base pt-4">{{ card.heading }}</p>
            <div :class="`${ card.linkUrl ? 'cursor-pointer' : '' } flex flex-row justify-between items-end`">
              <span class="font-bold text-lg">{{ card.subheading }}</span>
              <div v-if="card.linkUrl" class="tabbed-card-button border rounded-full flex justify-center items-center" style="width: 1.75rem; height: 1.75rem">
                <base-icon icon-name="icon-arrow-outward" width="10" height="10" viewbox="0 0 10 10" class="icon-arrow-outward">
                  <icon-arrow-outward></icon-arrow-outward>
                </base-icon>
              </div>
            </div>
          </div>
        </component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      currentTabIndex: this.data.tabs.length > 0 ? 0 : null
    };
  },
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    IconArrowOutward: () => import(/* webpackChunkName: "icon-arrow-outward" */ "../icons/IconArrowOutward.vue")
  },
  computed: {
    currentTabContent() {
      return this.data.tabs[this.currentTabIndex];
    }
  },
  methods: {
    currentTabClasses(index) {
      return this.currentTabIndex === index ? "font-bold underline" : "";
    },
    setCurrentTab(index) {
      this.currentTabIndex = index;
    }
  }
};
</script>
<style scoped>
.tabbed-card .tabbed-card-image {
  transition: transform 0.2s;
}

.tabbed-card:hover {
  .tabbed-card-image {
    transform: scale(1.10);
  }

  .tabbed-card-button {
    color: #fff;
    background-color: #000;
    transition: color 0.2s, background-color 0.2s;
  }
}
</style>
