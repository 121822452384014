var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      d:
        "M1.76634 9.00016L0.833008 8.06683L7.23301 1.66683H1.49967V0.333496H9.49967V8.3335H8.16634V2.60016L1.76634 9.00016Z",
      fill: "currentColor"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }