<template>
      <div class="bg-taupe p-8 rounded-lg flex flex-col gap-1">
            <base-heading size="h1" :uppercase="true" color="black">
              {{ product_name }}
            </base-heading>
            <base-rich-text v-if="product_cont">
              {{ product_cont }}
            </base-rich-text>
            <base-rich-text>
              <slot name="suggested_product"></slot>
            </base-rich-text>
            <base-heading v-if="subheading" size="h3" color="black">
              {{ subheading }}
            </base-heading>
            <base-rich-text>
              <div class="pt-8">
                <slot name="description"></slot>
              </div>
            </base-rich-text>
            <div class="mt-6 py-2 px-2 bg-brown rounded-lg text-white text-center" v-if="add_to_cart_callout">
              <base-rich-text v-html="add_to_cart_callout"></base-rich-text>
            </div>
            <div class="mt-12 pt-12 border-t-1 border-black">
              <add-to-cart-box v-if="is_purchasable === 'true'">
                <template slot="price_footnote">
                  <slot name="price_footnote"></slot>
                </template>
                <template slot="bulk_prices_text">
                  <slot name="bulk_prices_text"></slot>
                </template>
              </add-to-cart-box>
              <div v-else class="relative z-30 px-5 pt-5 pb-4 text-white shadow-md box bg-brown">
                <div class="text-3xl font-bold lg:text-4xl">
                  This product is currently unavailable.
                </div>
              </div>
            </div>
      </div>
</template>

<script>
export default {
  props: [
    "product_name",
    "product_cont",
    "subheading",
    "product_code",
    "is_purchasable",
    "product_thumbnail_src",
    "product_thumbnail_retina_src",
    "list_price",
    "product",
    "stock",
    "add_to_cart_url",
    "add_to_cart_callout",
    "has_price_footnote",
    "essential_products_heading",
    "essential_products_text",
    "price_tiers",
    "essential_products",
    "requires_sales_contact",
    "price_override",
    "product_versions",
    "product_versions_heading",
  ],
  components: {
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    AddToCartBox: () => import(/* webpackChunkName: "add-to-cart-box" */ "./add-to-cart/AddToCartBox.vue"),
    ProductVersion: () => import(/* webpackChunkName: "product-version" */ "./ProductVersion.vue")
  }
};
</script>

<style scoped>
.stock {
  letter-spacing: 1.5px;
}
</style>
