<template>
  <div class="add-to-cart-essential-products">
    <div v-if="errorMessage" class="px-5 py-4 font-bold text-red text-lg" v-html="errorMessage"></div>
    <div v-if="successMessage" class="px-5 py-4 font-bold text-green text-lg" v-html="successMessage"></div>
    <div v-if="isAdding" class="px-5 py-4 font-bold text-black text-lg"><span>Updating your cart</span></div>
    <div class="products text-base leading-normal">
      <div v-for="(group, g) in groupedEssentialProducts" :key="g">
        <div v-for="(item, i) in group" class="py-2 px-5 flex gap-x-3 border-b border-brown" :key="item.code">
          <div class="flex-1 lg:w-1/2">
            <div>{{ item.title }}</div>
            <div class="text-xs mt-1">Product Code: {{ item.code }}</div>
          </div>
          <div class="w-1/5">
            <div class="flex flex-col justify-between gap-y-4">
              <div class="text-xs uppercase product-stock" v-html="item.stock"></div>
              <div class="flex items-end">
                <p>
                  <span class="text-lg font-bold">{{ $root.formattedPrice(item.price) }}</span>
                  <span class="text-xs">{{ $root.formattedCurrency() }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-y-2">
            <div class="text-xs uppercase">Qty:</div>
            <input-product-quantity
              v-model="groupedEssentialProducts[g][i].qty"
              :value="groupedEssentialProducts[g][i].qty"
              border_color="brown"
              bg_color="grey-light"
              :is_disabled="isAdding"
              class_names="sm:mx-auto"
            ></input-product-quantity>
          </div>
          <div class="w-1/5 text-right pt-6 pl-1 flex justify-end items-start">
            <button
              type="submit"
              @click.prevent="addItemToCart(groupedEssentialProducts[g][i])"
              :disabled="isAdding || groupedEssentialProducts[g][i].qty < 1"
              :class="[
                'flex-1 flex justify-center items-center gap-x-2 button uppercase tr-all rounded text-xs md:text-base font-bold leading-tight text-center border-2 cursor-pointer w-full py-2 px-1 md:px-2',
                isAdding || groupedEssentialProducts[g][i].qty < 1
                  ? 'text-grey bg-white'
                  : 'bg-white border-brown text-brown hover:bg-grey-hover'
              ]"
            >
              <base-icon icon-name="loading" width="12" height="12" viewbox="0 0 32 32" class="loading" v-if="isAdding">
                <icon-loading></icon-loading>
              </base-icon>
              <span v-else>Add to Order</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-5 py-6">
      <div class="flex justify-between">
        <div class="w-p48">
          <base-button
            url="/shop/cart"
            label="View Cart"
            color="brown"
            outline="true"
            size="smaller"
            class_names="w-full"
          ></base-button>
        </div>
        <div class="w-p48">
          <base-button url="/shop/checkout/shipping" label="Checkout" color="red" size="smaller" class_names="w-full"></base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueAxios from "../../../js/modules/vue-axios";
import { gaAddToCart } from "../../../js/modules/wa-analytics";

export default {
  data: function() {
    return {
      isAdding: false,
      products: this.$parent.$parent.$parent.essential_products,
      errorMessage: "",
      successMessage: ""
    };
  },
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../../components/BaseButton.vue"),
    AddToCartBoxEssentialProducts: () =>
      import(/* webpackChunkName: "add-to-cart-box-essential-products" */ "./AddToCartBoxEssentialProducts.vue"),
    InputProductQuantity: () => import(/* webpackChunkName: "input-product-quantity" */ "../../components/InputProductQuantity.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../../icons/IconLoading.vue")
  },
  methods: {
    clearMessages() {
      this.errorMessage = "";
      this.successMessage = "";
    },
    addItemToCart(product) {
      let self = this;
      self.clearMessages();

      if (product.qty < 1) {
        return;
      }

      self.isAdding = true;
      // loop through products
      let formData = new FormData(),
        numProductsToAdd = 0;
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append("action", "wildlife/shop/update");
      const productCode = product.code.replace(/\\/g, "\\\\");
      formData.append("quantity_" + productCode, product.qty);
      formData.append("add_" + productCode, "");

      let options = {
        method: "POST",
        data: formData,
        url: "/"
      };
      VueAxios(options)
        .then(response => {
          let data = response.data || null;
          if (data.success) {
            self.errorMessage = `${product.title} was successfully added to your cart`;

            // Send to Google Analytics
            let productsData = [];
            productsData.push({
              name: product.title,
              id: product.code,
              price: product.price,
              brand: self.$root.getSiteFullName(),
              quantity: product.qty
            });
            const productQtyTotal = productsData.reduce((partialSum, p) => {
              return partialSum + p;
            });
            gaAddToCart(
              productsData,
              {
                list: "Recommended Products"
              },
              productQtyTotal,
              self.$root.currencyCode()
            );
            self.isAdding = false;
          } else {
            self.errorMessage = "An error occurred. Please try again.";
            self.isAdding = false;
          }
        })
        .catch(error => {
          self.errorMessage = "An error occurred. Please try again.";
          self.isAdding = false;
        });
    },
    chunkArray(array, size) {
      const chunked_arr = [];
      for (let i = 0; i < array.length; i++) {
        const last = chunked_arr[chunked_arr.length - 1];
        if (!last || last.length === size) {
          chunked_arr.push([array[i]]);
        } else {
          last.push(array[i]);
        }
      }
      return chunked_arr;
    }
  },
  computed: {
    totalQuantity() {
      let total = 0;
      this.products.forEach(function(product) {
        if (product.qty > 0) {
          total = total + parseInt(product.qty);
        }
      });
      return total;
    },
    groupedEssentialProducts() {
      return this.chunkArray(this.products, 3);
    }
  }
};
</script>

<style scoped>
.products {
  .c1 {
    width: 45%;
  }

  .c2 {
    width: 27%;
  }

  .c3 {
    width: 18%;
  }

  .c4 {
    width: 10%;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.product-stock >>> a {
  @apply underline text-red;
}
</style>
