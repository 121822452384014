var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col justify-center gap-y-8 pt-24 pb-24" },
    [
      _c(
        "p",
        {
          staticClass:
            "text-center font-bold text-2xl md:text-4xl lg:text-6xl xl:text-7xl"
        },
        [_vm._v(_vm._s(_vm.data.heading))]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex gap-x-8 overflow-x-auto" },
        _vm._l(_vm.data.tabs, function(tab, i) {
          return _c(
            "button",
            {
              key: i,
              class:
                _vm.currentTabClasses(i) +
                " md:text-lg lg:text-2xl hover:text-grey-dark",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.setCurrentTab(i)
                }
              }
            },
            [_vm._v("\n        " + _vm._s(tab.heading) + "\n      ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex flex-col" }, [
        _c("p", { staticClass: "uppercase text-sm" }, [
          _vm._v(_vm._s(_vm.currentTabContent.subheading))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pt-2 flex flex-col md:flex-row gap-y-8 gap-x-8" },
          _vm._l(_vm.currentTabContent.cards, function(card, i) {
            return _c(
              card.linkUrl ? "a" : "div",
              {
                key: i,
                tag: "component",
                class:
                  (card.linkUrl ? "cursor-pointer" : "") +
                  " w-full md:w-1/4 tabbed-card group",
                attrs: { target: card.linkTarget, href: card.linkUrl }
              },
              [
                _c("div", { staticClass: "flex flex-col" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-lg bg-grey-lighter2 h-48 w-full tabbed-card-image flex justify-center items-center"
                    },
                    [
                      _c("img", {
                        staticClass: "object-fit h-full",
                        attrs: {
                          srcset:
                            card.image.src +
                            " 1x, " +
                            card.image.srcRetina +
                            " 2x",
                          alt: card.image.alt
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-base pt-4" }, [
                    _vm._v(_vm._s(card.heading))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class:
                        (card.linkUrl ? "cursor-pointer" : "") +
                        " flex flex-row justify-between items-end"
                    },
                    [
                      _c("span", { staticClass: "font-bold text-lg" }, [
                        _vm._v(_vm._s(card.subheading))
                      ]),
                      _vm._v(" "),
                      card.linkUrl
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "tabbed-card-button border rounded-full flex justify-center items-center",
                              staticStyle: {
                                width: "1.75rem",
                                height: "1.75rem"
                              }
                            },
                            [
                              _c(
                                "base-icon",
                                {
                                  staticClass: "icon-arrow-outward",
                                  attrs: {
                                    "icon-name": "icon-arrow-outward",
                                    width: "10",
                                    height: "10",
                                    viewbox: "0 0 10 10"
                                  }
                                },
                                [_c("icon-arrow-outward")],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ])
              ]
            )
          }),
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }