<template>
  <div class="flex flex-col gap-y-8">
    <div class="relative">
      <base-image :disable_lazy="true" :src="selectedImage.src" :retina_src="selectedImage.retinaSrc"
        :alt="selectedImage.alt" class_names="w-full h-96 lg:h-148 object-cover rounded-lg"></base-image>
      <div v-if="product_versions.length > 0" class="absolute flex flex-col gap-y-2 versions">
        <component :is="product_versions.length > 1 ? 'button' : 'div'" @click.prevent="toggleVersions" :class="[
          'bg-black opacity-75 text-white text-base',
          product_versions.length > 1 ? 'cursor-pointer' : '',
          showVersions ? 'active' : ''
        ]">
          <div class="border-b-4 border-white px-6 py-1 w-full flex justify-between items-center gap-x-4">
            <span>{{ versionsHeading }}</span>
            <span v-if="product_versions.length > 1" class="arrow text-white"></span>
          </div>
        </component>
        <slide-up-down :active="product_versions.length === 1 || showVersions" :duration="100">
          <div class="flex flex-col gap-y-2">
            <a v-for="version in product_versions" :key="version.id" :href="version.url" target="_blank"
              class="px-6 py-2 bg-black opacity-75 text-white hover:opacity-100 rounded-full text-xl flex flex-row items-center justify-between gap-x-2 cursor-pointer">
              <span>{{ version.product_version_name }}</span>
              <span class="border border-white rounded-full flex justify-center items-center"
                style="width: 1.75rem; height: 1.75rem">
                <base-icon :icon-name="`product-version-${version.id}`" width="7" height="7" viewbox="0 0 10 10">
                  <icon-arrow-outward></icon-arrow-outward>
                </base-icon>
              </span>
            </a>
          </div>
        </slide-up-down>
      </div>
    </div>
    <div v-if="images.length > 1" class="overflow-x-auto whitespace-no-wrap">
      <button v-for="(thumbnail, index) in thumbnails" :key="index" @click.prevent="setSelectedImage(index)"
        class="w-28 h-28 px-1">
        <base-image :disable_lazy="true" :src="thumbnail.src" :retina_src="thumbnail.retinaSrc" :alt="thumbnail.alt"
          class_names="rounded-lg w-full"></base-image>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["images", "thumbnails", "versions", "product_versions", "product_versions_heading"],
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    IconArrowOutward: () => import(/* webpackChunkName: "icon-arrow-outward" */ "../icons/IconArrowOutward.vue")
  },
  data: function () {
    return {
      selectedImage: this.images.length > 0 ? this.images[0] : null,
      showVersions: false
    };
  },
  computed: {
    versionsHeading() {
      return this.product_versions_heading && this.product_versions_heading.length
        ? this.product_versions_heading
        : "ALTERNATIVE VERSIONS AVAILABLE";
    }
  },
  methods: {
    setSelectedImage(index) {
      this.selectedImage = this.images[index] ?? null;
    },
    toggleVersions() {
      this.showVersions = !this.showVersions;
    }
  }
};
</script>
<style scoped>
.versions {
  bottom: 1rem;
  left: 1rem;

  button {
    width: max-content;

    .arrow {
      width: 0;
      height: 0;
      border-style: solid;
    }

    &.active {
      .arrow {
        border-width: 0 9px 8px 9px;
        border-color: transparent transparent #fff transparent;
      }
    }

    &:not(.active) {
      .arrow {
        border-width: 8px 9px 0 9px;
        border-color: #fff transparent transparent transparent;
      }
    }
  }
}
</style>
