var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "block mixed-content-block",
        _vm.background_color,
        _vm.background_color === "bg-white" ? "white-block" : ""
      ]
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "py-8 lg:py-10 xl:py-12"
          ]
        },
        [
          _c("div", { staticClass: "flex flex-col lg:flex-row gap-y-8" }, [
            _c(
              "div",
              {
                class: [
                  "flex flex-col gap-y-4",
                  _vm.hasSidebarBlocks ? "lg:w-3/8" : "lg:w-1/2"
                ]
              },
              [
                _vm.heading
                  ? _c(
                      "div",
                      [
                        _c("base-heading", { attrs: { size: "h1" } }, [
                          _vm._v(_vm._s(_vm.heading))
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.has_text
                  ? _c("div", [_c("base-rich-text", [[_vm._t("text")]], 2)], 1)
                  : _vm._e(),
                _vm._v(" "),
                _vm.facebook_button
                  ? _c("div", { staticClass: "facebook-button text-lg" }, [
                      _c(
                        "a",
                        {
                          staticClass: "inline-flex items-center",
                          attrs: {
                            href: _vm.facebook_button.url,
                            target: _vm.facebook_button.target
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("social-icon", {
                                attrs: {
                                  type: "facebook",
                                  class_names: "inline-block",
                                  icon_size: "32"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "pl-3" }, [
                            _vm._v(
                              "\n                          " +
                                _vm._s(
                                  _vm.facebook_button.text
                                    ? _vm.facebook_button.text
                                    : ""
                                ) +
                                "\n                        "
                            )
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.image
              ? _c(
                  "div",
                  {
                    class: [
                      _vm.hasSidebarBlocks ? "lg:w-3/8 pl-2 pr-8" : "lg:w-1/2"
                    ]
                  },
                  [
                    _c("base-image", {
                      attrs: {
                        src: _vm.image.src,
                        retina_src: _vm.image.retina,
                        srcset_webp: _vm.image.webpSrcset,
                        class_names: "mx-auto",
                        alt: _vm.image.alt
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasSidebarBlocks
              ? _c(
                  "div",
                  { staticClass: "lg:w-1/4" },
                  [
                    _c("sidebar-blocks", {
                      attrs: { blocks: _vm.sidebar_blocks }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }