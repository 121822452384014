<template>
  <div :class="[
          'simple-header',
          `bg-${background_color}`,
          background_color === 'brown' ? 'text-links-on-brown' : ''
       ]">
    <div
      :class="[
        'print-hidden container mx-auto px-5-safe text-white',
        logged_in_as ? 'pt-6 pb-1 lg:pt-8 xl:pt-10' : 'py-6 lg:py-8 xl:py-10'
      ]"
    >

      <!-- leading heading -->
      <h3 class="font-bold lead mb-2 text-xl lg:text-2xl xl:text-3xl" v-if="leading_heading !== ''">
        <a :href="leading_heading_url" class="text-brown-lighter hover:text-brown-lightest relative" v-if="leading_heading_url !== ''">
          <base-icon icon-name="angle-left" width="9" height="16" viewbox="0 0 9 17" class="arrow absolute centered-y">
            <icon-angle-left></icon-angle-left>
          </base-icon>
          {{ leading_heading }}
        </a>
        <span v-else>
          {{ leading_heading }}
        </span>
      </h3>

      <!-- main heading -->
      <base-heading size="h1" color="white" :uppercase="true" :class_names="`text-${large_heading_alignment ? large_heading_alignment : 'left'}`" v-html="large_heading"></base-heading>

      <!-- text -->
      <base-rich-text v-if="has_text" size="larger" class_names="mt-3">
        <slot></slot>
      </base-rich-text>

      <!-- footnote -->
      <base-rich-text v-if="footnote" size="tiny" class_names="mt-3" v-html="footnote"></base-rich-text>
    </div>
    <account-bar v-if="logged_in_as" :name="logged_in_as"></account-bar>
    <div class="print-only container px-5-safe pb-2 mx-auto">
      <base-heading size="h1" color="black" :uppercase="true">{{ large_heading }}</base-heading>
    </div>
  </div>
</template>

<script>
// @group Headers

export default {
  props: ["background_color", "leading_heading", "leading_heading_url", "large_heading", "has_text", "footnote", "logged_in_as", "large_heading_alignment"],
  components: {
    AccountBar: () => import(/* webpackChunkName: "account-bar" */ "./AccountBar.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../BaseHeading.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../BaseIcon.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../BaseRichText.vue"),
    IconAngleLeft: () => import(/* webpackChunkName: "icon-angle-left" */ "../../icons/IconAngleLeft.vue")
  }
};
</script>

<style scoped>
svg.arrow {
  opacity: 0;
  transition: opacity 0.1s;
  width: 9px;
  height: 16px;
  left: -18px;
}
@media (min-width: 768px) {
  svg.arrow {
    width: 10px;
    height: 18px;
    left: -20px;
  }
}
@media (min-width: 992px) {
  svg.arrow {
    width: 12px;
    height: 20px;
    left: -22px;
  }
}
@media (min-width: 1280px) {
  svg.arrow {
    width: 14px;
    height: 22px;
    left: -24px;
  }
}
@media (min-width: 600px) and (max-width: 767px),
  (min-width: 800px) and (max-width: 991px),
  (min-width: 1016px) and (max-width: 1279px),
  (min-width: 1305px) {
  a:hover svg.arrow {
    opacity: 1;
  }
}
</style>
