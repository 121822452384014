var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-taupe p-8 rounded-lg flex flex-col gap-1" },
    [
      _c(
        "base-heading",
        { attrs: { size: "h1", uppercase: true, color: "black" } },
        [_vm._v("\n        " + _vm._s(_vm.product_name) + "\n      ")]
      ),
      _vm._v(" "),
      _vm.product_cont
        ? _c("base-rich-text", [
            _vm._v("\n        " + _vm._s(_vm.product_cont) + "\n      ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("base-rich-text", [_vm._t("suggested_product")], 2),
      _vm._v(" "),
      _vm.subheading
        ? _c("base-heading", { attrs: { size: "h3", color: "black" } }, [
            _vm._v("\n        " + _vm._s(_vm.subheading) + "\n      ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("base-rich-text", [
        _c("div", { staticClass: "pt-8" }, [_vm._t("description")], 2)
      ]),
      _vm._v(" "),
      _vm.add_to_cart_callout
        ? _c(
            "div",
            {
              staticClass:
                "mt-6 py-2 px-2 bg-brown rounded-lg text-white text-center"
            },
            [
              _c("base-rich-text", {
                domProps: { innerHTML: _vm._s(_vm.add_to_cart_callout) }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-12 pt-12 border-t-1 border-black" },
        [
          _vm.is_purchasable === "true"
            ? _c(
                "add-to-cart-box",
                [
                  _c(
                    "template",
                    { slot: "price_footnote" },
                    [_vm._t("price_footnote")],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "bulk_prices_text" },
                    [_vm._t("bulk_prices_text")],
                    2
                  )
                ],
                2
              )
            : _c(
                "div",
                {
                  staticClass:
                    "relative z-30 px-5 pt-5 pb-4 text-white shadow-md box bg-brown"
                },
                [
                  _c("div", { staticClass: "text-3xl font-bold lg:text-4xl" }, [
                    _vm._v(
                      "\n            This product is currently unavailable.\n          "
                    )
                  ])
                ]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }