var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "block comparison-chart-three-column white-block" },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "pt-10 pb-14 lg:py-20"
          ]
        },
        [
          _vm.data.heading
            ? _c("base-heading", { attrs: { size: "h1", color: "brown" } }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.data.heading) + "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "mt-8 text-base sm:text-lg leading-compact sm:leading-normal"
            },
            [
              _c(
                "div",
                { staticClass: "mb-3 sm:hidden" },
                [_c("swipe-table-indicator")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "table-wrapper min-sm" }, [
                _c("table", { staticClass: "w-full" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _c("td", { staticClass: "c1" }, [_vm._v(" ")]),
                        _vm._v(" "),
                        _vm._l(_vm.data.columnHeaders, function(header, h) {
                          return _c(
                            "td",
                            {
                              key: h,
                              class: [
                                "c2 bg-brown-lighter text-white p-3 sm:p-5",
                                "border-t border-r border-l border-brown"
                              ]
                            },
                            [
                              _c(
                                "strong",
                                {
                                  staticClass: "text-lg md:text-xl lg:text-2xl"
                                },
                                [_vm._v(_vm._s(header.heading))]
                              ),
                              _vm._v(" "),
                              header.subheading
                                ? _c("div", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(header.subheading) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.data.rows, function(row, r) {
                        return _c(
                          "tr",
                          { key: r },
                          [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "c1 text-lg py-5 pr-5 border-t border-r border-brown"
                              },
                              [
                                _c(
                                  "strong",
                                  {
                                    staticClass:
                                      "text-lg md:text-xl lg:text-2xl"
                                  },
                                  [_vm._v(_vm._s(row.rowHeading))]
                                ),
                                _vm._v(" "),
                                row.rowSubheading
                                  ? _c("div", { staticClass: "mt-1" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(row.rowSubheading) +
                                          "\n                                "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.data.columnHeaders.length, function(n) {
                              return _c(
                                "td",
                                {
                                  key: n,
                                  staticClass:
                                    "c2 text-center p-3 sm:p-5 align-middle border-t border-r border-brown"
                                },
                                [
                                  row.valuesCheckmarks[n]
                                    ? _c("base-image", {
                                        attrs: {
                                          src: "/img/chart-checkmark.png",
                                          retina_src:
                                            "/img/chart-checkmark@2x.png"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.valuesText
                                    ? _c("div", {
                                        staticClass: "mt-2",
                                        domProps: {
                                          innerHTML: _vm._s(row.valuesText[n])
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "tr",
                        [
                          _c(
                            "td",
                            {
                              staticClass: "c1 border-t border-r border-brown"
                            },
                            [_vm._v(" ")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.data.columnHeaders.length, function(b) {
                            return _c(
                              "td",
                              {
                                key: b,
                                staticClass:
                                  "c2 p-3 sm:p-5 border-t border-r border-brown"
                              },
                              [
                                _vm.data.buttons[b]
                                  ? _c(
                                      "div",
                                      [
                                        _c("base-button", {
                                          attrs: {
                                            url: _vm.data.buttons[b].url,
                                            label: _vm.data.buttons[b].text,
                                            color: "red",
                                            class_names: "w-full text-center",
                                            target: _vm.data.buttons[b].target
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "tr",
                        [
                          _c("td", { staticClass: "c1" }, [_vm._v(" ")]),
                          _vm._v(" "),
                          _vm._l(_vm.data.columnHeaders.length, function(f) {
                            return _c(
                              "td",
                              {
                                key: f,
                                staticClass:
                                  "c2 px-3 sm:px-5 text-base text-center align-text-top"
                              },
                              [
                                _vm.data.footers[f]
                                  ? _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.data.footers[f])
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    2
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }