<template>
  <component
    :is="type === 'submit' ? 'button' : 'a'"
    :type="type === 'submit' ? 'submit' : null"
    :href="type !== 'submit' ? url : null"
    :value="value || null"
    :name="name"
    @click="onClick"
    :class="[
      class_names,
      'button inline-block uppercase tr-all rounded font-bold leading-tighter text-center cursor-pointer',
      textsize ? textsize : 'text-lg xl:text-xl',
      fat_outline ? 'border-4' : '',
      thin_outline ? 'border-1' : '',
      !fat_outline && !thin_outline ? 'border-3' : '',
      !disabled ? `border-${color}` : 'border-grey',
      !disabled ? `hover:border-${color}-hover` : '',
      !disabled ? `text-${outline ? color : 'white'}` : 'text-grey',
      'bg-' + (outline ? 'transparent' : !disabled ? color : 'grey'),
      outline ? 'hover:bg-grey-light' : `hover:bg-${color}-hover`,
      p !== undefined ? p : paddingClasses,
    ]"
    :target="target || null"
  >
    {{ label }}
  </component>
</template>

<script>
export default {
  props: [
    "type",
    "url",
    "target",
    "label",
    "color",
    "outline",
    "fat_outline",
    "thin_outline",
    "p",
    "size",
    "textsize",
    "class_names",
    "name",
    "disabled",
    "value"
  ],
  methods: {
    onClick() {
      this.$emit("on_click");
    }
  },
  computed: {
    paddingClasses() {
      let smaller = false,
        tiny = false,
        wider = false,
        extrawide = false;
      if (this.size) {
        (smaller = this.size.indexOf("smaller") !== -1),
	(tiny = this.size.indexOf("tiny") !== -1),
          (wider = this.size.indexOf("wider") !== -1),
          (extrawide = this.size.indexOf("extrawide") !== -1);
      }
      if (smaller && wider) {
        return "px-8 py-2";
      } else if (smaller) {
        return "px-4 py-2";
      } else if (wider) {
        return "px-8 py-2 xl:py-3";
      } else if (extrawide) {
        return "px-8 lg:px-16 py-2 xl:py-3";
      } else if (tiny) {
        return "px-2 py-1";
      } else {
        return "px-4 py-2 xl:px-5 xl:py-3";
      }
    }
  }
};
</script>
