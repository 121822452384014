<template>
  <section class="cta-subscribe py-8 lg:py-10 px-5 container mx-auto rounded-lg text-white flex flex-row">
    <div class="bg-cream py-24 rounded-lg px-5-safe lg:px-16-safe flex flex-col lg:flex-row">
      <div v-if="success" class="text-black text-links-on-brown">
        <div class="flex flex-col pb-12 gap-y-8 lg:pb-0 lg:w-1/2 px-4 lg:px-16">
          <p class="text-4xl lg:text-5xl xl:text-7xl text-black font-bold">
            {{ heading }}
          </p>
          <div v-if="has_thank_you_text" class="rich-text">
            <slot name="thanks"></slot>
          </div>
          <div v-else class="rich-text">
            <p>Thank you for signing up to our mailing list.</p>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="flex flex-col pb-12 lg:pb-0 lg:w-1/2 px-4 lg:px-16">
          <p class="text-4xl lg:text-5xl xl:text-7xl text-black font-bold">
            {{ heading }}
          </p>
          <div class="mt-4 rich-text text-lg text-black lg:text-xl" v-if="has_text">
            <slot name="text"></slot>
          </div>
        </div>
        <div class="flex flex-col lg:w-1/2 px-4 lg:px-16">
          <div v-if="!success">
            <div class="mt-10 max-w-lg mx-auto text-left text-lg">
              <div class="sm:flex sm:justify-between sm:flex-wrap">
                <div class="sm:w-p48 mb-5">
                  <form-input input_type="text" placeholder="First Name" name="fname" v-model="fname" :disabled="isLoading"></form-input>
                </div>
                <div class="sm:w-p48 mb-5">
                  <form-input input_type="text" placeholder="Last Name" name="lname" v-model="lname" :disabled="isLoading"></form-input>
                </div>
                <div class="w-full mb-5">
                  <form-input input_type="text" placeholder="Email *" name="email" v-model="email" :disabled="isLoading"></form-input>
                </div>
                <div class="w-full mb-5">
                  <form-select
                    name="orgtype"
                    title="Organization type *"
                    :options="org_types"
                    v-model="orgtype"
                    :disabled="isLoading"
                  ></form-select>
                </div>
                <div class="w-full mb-5">
                  <div class="select-wrapper relative">
                    <form-select name="country" title="Country" :options="countries" v-model="country" :disabled="isLoading"></form-select>
                  </div>
                </div>
              </div>
              <div class="mt-4 text-lg leading-normal text-red" v-if="errors.length > 0 || message">
                <span v-for="error in errors">* {{ error }}<br /></span>
                <span v-if="message" v-html="message"></span>
              </div>
              <div class="mt-6 text-center w-full">
                <button
                  type="button"
                  @click.prevent="submit()"
                  :disabled="isLoading"
                  :class="[
                    'relative w-full bg-brown uppercase rounded text-base font-bold tracking-wide py-6 tr-color tr-bg hover:bg-brown-lighter',
                    isLoading ? 'pl-14 pr-16 text-white border-grey' : 'px-16 text-white hover:bg-brown-lighter'
                  ]"
                >
                  <span class="inline">{{ isLoading ? 'Submitting' : 'Subscribe' }}</span>
                  <base-icon
                    icon-name="loading"
                    width="10"
                    height="10"
                    viewbox="0 0 32 32"
                    class="ml-2"
                    v-if="isLoading"
                  >
                    <icon-loading></icon-loading>
                  </base-icon>
                  <base-icon
                    icon-name="subscribe"
                    width="10"
                    height="10"
                    viewbox="0 0 10 10"
                    class="ml-2"
                    v-if="!isLoading"
                  >
                    <icon-arrow-outward></icon-arrow-outward>
                  </base-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import VueAxios from "../../js/modules/vue-axios";

export default {
  data: function() {
    return {
      fname: "",
      lname: "",
      email: "",
      orgname: "",
      orgtype: "",
      country: "",
      referred: "",
      tsb_mini: false,
      tsb_sm4: false,
      tsb_emt2: false,
      tsb_kpro: false,
      errors: [],
      success: false,
      message: null,
      isLoading: false
    };
  },
  props: ["heading", "has_text", "has_thank_you_text", "countries", "org_types"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "./BaseHeading.vue"),
    FormInput: () => import(/* webpackChunkName: "form-input" */ "./form/FormInput.vue"),
    FormCheckbox: () => import(/* webpackChunkName: "form-input" */ "./form/FormCheckbox.vue"),
    FormRadioGroup: () => import(/* webpackChunkName: "form-radio-group" */ "./form/FormRadioGroup.vue"),
    FormSelect: () => import(/* webpackChunkName: "form-select" */ "./form/FormSelect.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../icons/IconLoading.vue"),
    IconArrowOutward: () => import(/* webpackChunkName: "icon-arrow-outward" */ "../icons/IconArrowOutward.vue")
  },
  methods: {
    submit() {
      let self = this;
      self.errors = [];
      if (self.email === "") {
        self.errors.push("Please enter your Email Address.");
      }
      if (self.orgtype === "") {
        self.errors.push("Please specify an organization type.");
      }
      if (self.country === "") {
        self.errors.push("Please specify a country.");
      }
      if (self.errors.length === 0) {
        self.isLoading = true;
        let formData = new FormData();
        formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
        formData.append("fname", self.fname);
        formData.append("lname", self.lname);
        formData.append("email", self.email);
        formData.append("orgname", "");
        formData.append("orgtype", self.orgtype);
        formData.append("country", self.country);
        formData.append("referred", "");
        formData.append("tsb_mini", self.tsb_mini);
        formData.append("tsb_kpro", self.tsb_kpro);
        formData.append("tsb_emt2", self.tsb_emt2);
        formData.append("tsb_sm4", self.tsb_sm4);
        formData.append("action", "wildlife/mail-list/signup");
        let options = {
          method: "POST",
          data: formData,
          url: "/"
        };
        VueAxios(options)
          .then(response => {
            let data = response.data || null;
            self.isLoading = false;
            if (data.success) {
              self.success = true;
            } else {
              self.message = data.message || null;
            }
          })
          .catch(error => {
            self.message = "An error occurred. Please try again.";
            self.isLoading = false;
          });
      }
    }
  }
};
</script>
