var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "simple-header",
        "bg-" + _vm.background_color,
        _vm.background_color === "brown" ? "text-links-on-brown" : ""
      ]
    },
    [
      _c(
        "div",
        {
          class: [
            "print-hidden container mx-auto px-5-safe text-white",
            _vm.logged_in_as
              ? "pt-6 pb-1 lg:pt-8 xl:pt-10"
              : "py-6 lg:py-8 xl:py-10"
          ]
        },
        [
          _vm.leading_heading !== ""
            ? _c(
                "h3",
                {
                  staticClass:
                    "font-bold lead mb-2 text-xl lg:text-2xl xl:text-3xl"
                },
                [
                  _vm.leading_heading_url !== ""
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "text-brown-lighter hover:text-brown-lightest relative",
                          attrs: { href: _vm.leading_heading_url }
                        },
                        [
                          _c(
                            "base-icon",
                            {
                              staticClass: "arrow absolute centered-y",
                              attrs: {
                                "icon-name": "angle-left",
                                width: "9",
                                height: "16",
                                viewbox: "0 0 9 17"
                              }
                            },
                            [_c("icon-angle-left")],
                            1
                          ),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.leading_heading) +
                              "\n      "
                          )
                        ],
                        1
                      )
                    : _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.leading_heading) +
                            "\n      "
                        )
                      ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("base-heading", {
            attrs: {
              size: "h1",
              color: "white",
              uppercase: true,
              class_names:
                "text-" +
                (_vm.large_heading_alignment
                  ? _vm.large_heading_alignment
                  : "left")
            },
            domProps: { innerHTML: _vm._s(_vm.large_heading) }
          }),
          _vm._v(" "),
          _vm.has_text
            ? _c(
                "base-rich-text",
                { attrs: { size: "larger", class_names: "mt-3" } },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.footnote
            ? _c("base-rich-text", {
                attrs: { size: "tiny", class_names: "mt-3" },
                domProps: { innerHTML: _vm._s(_vm.footnote) }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.logged_in_as
        ? _c("account-bar", { attrs: { name: _vm.logged_in_as } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "print-only container px-5-safe pb-2 mx-auto" },
        [
          _c(
            "base-heading",
            { attrs: { size: "h1", color: "black", uppercase: true } },
            [_vm._v(_vm._s(_vm.large_heading))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }