<template>
  <div>
    <header :class="['site-header print-hidden', `${header_style}-style`]">
      <div :class="['site-header__top hidden  lg:block', header_style === 'light' ? 'bg-white' : 'bg-brown', text_color]">
        <utility-nav></utility-nav>
      </div>

      <div class="site-header__main">
        <div :class="['relative pt-5-safe pb-5', header_style === 'dark' ? 'lg:pt-5-safe' : 'lg:pt-1-safe']">
          <div class="bg-white">
            <div class="relative z-40">
              <div class="relative container mx-auto px-5-safe flex justify-between items-center lg:items-end">
                <!-- the logo -->
                <div>
                  <a :href="logo_url">
                    <img
                      :src="header_style === 'light' ? logo_dark_src : logo_light_src"
                      :alt="logo_alt"
                      class="site-logo m-0 block h-12 lg:h-14 pr-4"
                    />
                  </a>
                </div>

                <!-- open mobile menu -->
                <div class="-mr-2 -my-2 flex flex-row items-center lg:hidden">
                    <div class="cart-btn relative mr-4">
                      <a href="/shop/cart">
                        <base-icon icon-name="cart" width="28" height="28" viewbox="0 0 28 28" class="text-brown h-8 w-8">
                          <icon-cart></icon-cart>
                        </base-icon>
                        <span class="block circle bg-red" v-show="$root.totalCartQuantity > 0"></span>
                      </a>
                    </div>
                  <button
                    type="button"
                    class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                    @click.stop.prevent="toggleMobileMenu()"
                  >
                    <span class="sr-only">Open menu</span>
                    <svg
                      class="h-12 w-12"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                </div>

                <!-- The main menu -->
                <div class="hidden lg:flex md:items-center md:justify-between">
                  <nav class="flex font-bold text-xl xl:text-hxl text-brown">
                    <div v-for="item_l1 in main_nav" class="ml-7">
                      <template v-if="item_l1.depth === 1">
                        <a :href="item_l1.url" :target="item_l1.target" class="top-link">
                          <span class="text relative">{{ item_l1.text }}</span>
                        </a>
                      </template>

                      <template v-else>
                        <button type="button" class="top-link font-bold" @click.stop.prevent="toggleDropdown(item_l1.text)">
                          <span :class="['relative', item_l1.text === activeMenu ? 'active-text' : 'text']">
                            {{ item_l1.text }}
                          </span>

                          <base-icon
                            icon-name="angle-down"
                            width="14"
                            height="7"
                            viewbox="0 0 17 9"
                            v-show="item_l1.children.length > 0"
                            :class="['angle inline', item_l1.text === activeMenu ? 'rotated' : '']"
                          >
                            <icon-angle-down></icon-angle-down>
                          </base-icon>
                        </button>

                        <div :class="[
                          'absolute hidden z-10 text-black font-regular font-base mt-4 items-center justify-center max-w-full shadow-sm',
                          item_l1.depth !== 2 && item_l1.depth ? 'w-full transform-center left-center' : '',
                          item_l1.text === activeMenu ? 'md:flex' : ''
                        ]" v-on-clickaway="closeDropdown">
                          <div class="bg-taupe flex w-full">
                            <!-- left CTA -->
                            <div v-if="item_l1.ctas.left" class="hidden lg:block w-1/4 px-6 py-10">
                              <header-cta :cta="item_l1.ctas.left"></header-cta>
                            </div>

                            <div :class="`${item_l1.ctas.right ? 'flex-1' : 'w-full'} px-6 py-10`">
                              <!-- links -->
                              <template v-if="item_l1.depth < 3">
                                <a :href="item_l1.url" :target="item_l1.target" :class="`text-2hxl font-bold pb-4 my-2 border-b-1 border-black ${item_l1.url && item_l1.url.length > 0 ? 'hover:border-red hover:text-red' : ''}`">
                                  {{ item_l1.text }}
                                </a>
                              </template>

                              <div class="flex flex-row flex-wrap">
                                <template v-if="item_l1.depth >= 3">
                                  <template v-for="item_l2 in item_l1.children">
                                    <div v-if="item_l2.type === 'navigationItem'" :class="`flex flex-col flex-grow px-5-safe ${item_l2.text == 'Reconyx Professional Cameras' || item_l2.text == 'Reconyx Economy Cameras' ? 'reconyx-level' : 'w-1/3'}`">
                                      <a :href="item_l2.url" :target="item_l2.target" :class="`text-2hxl font-bold py-4 border-b-1 border-black ${item_l2.url && item_l2.url.length > 0 ? 'hover:border-red' : ''}`">
                                        {{ item_l2.text }}
                                      </a>
                                      <ul class="menu-list flex flex-col py-4"> 
                                        <template v-for="item_l3 in item_l2.children"> 
                                          <li class="border-taupe border-b-1 recon-l4 hover:border-red">
                                            <a :href="item_l3.url" :target="item_l3.target" class="text-lg flex w-full py-2">
                                              {{ item_l3.text }}
                                            </a>
                                          </li>
                                          <div v-for="item_l4 in item_l3.children">
                                            <li class="border-taupe border-b-1 recon-l4 hover:border-red">
                                              <a :href="item_l4.url" :target="item_l4.target" class="text-lg flex w-full pl-6 py-2">
                                                {{ item_l4.text }}
                                              </a>
                                            </li>
                                            <template v-for="item_l5 in item_l4.children">
                                              <li class="border-b-1 border-taupe hover:border-red">
                                                <a :href="item_l5.url" :target="item_l5.target" class="text-lg flex w-full pl-10 py-2">
                                                  {{ item_l5.text }}
                                                </a>
                                              </li>
                                            </template>
                                          </div>
                                        </template>
                                      </ul>
                                    </div>
                                  </template>
                                </template>

                                <template v-if="item_l1.depth === 2">
                                  <div class="flex w-full flex-wrap mt-8">
                                    <ul class="menu-list w-full">
                                      <li v-for="item_l2 in item_l1.children" class="border-taupe border-b-1 hover:border-red">
                                        <a :href="item_l2.url" :target="item_l2.target" class="flex w-full py-2">{{ item_l2.text }}</a>
                                      </li>
                                    </ul>
                                  </div>
                                </template>
                              </div>

                              <div class="flex flex-row flex-wrap">
                                <div v-if="item_l2.type === 'ctaButton'" v-for="item_l2 in item_l1.children" class="flex flex-row pl-5-safe pt-8">
                                  <base-button
                                    :url="item_l2.url"
                                    :label="item_l2.text"
                                    :color="item_l2.ctaButton.color"
                                    :outline="item_l2.ctaButton.outlined"
                                    :thin_outline="true"
                                    :target="item_l2.target"
                                    size="wider"
                                    class_names="w-full"
                                  ></base-button>
                                </div>
                              </div>

                            </div>
                            <div v-if="item_l1.ctas.right" class="hidden lg:block w-1/4 px-6 py-10">
                              <header-cta :cta="item_l1.ctas.right"></header-cta>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <!-- mobile menu -->
            <div
              :class="[
                'absolute z-40 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden',
                mobileMenuActive ? '' : 'hidden'
              ]"
              v-on-clickaway="closeMobileMenu"
            >
              <div class="shadow bg-white">
                <div>
                  <!-- close button -->
                  <div class="flex items-center justify-between pt-5 pr-5 pl-8">
                    <div class="cart-btn relative">
                      <a href="/shop/cart">
                        <base-icon icon-name="cart" width="28" height="28" viewbox="0 0 28 28" class="text-brown h-10 w-10">
                          <icon-cart></icon-cart>
                        </base-icon>
                        <span class="block circle bg-red" v-show="$root.totalCartQuantity > 0"></span>
                      </a>
                    </div>
                    <div class="-mr-2">
                      <button
                        type="button"
                        class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                        @click.stop.prevent="toggleMobileMenu()"
                      >
                        <span class="sr-only">Close menu</span>
                        <!-- Heroicon name: outline/x -->
                        <svg
                          class="h-12 w-12"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div>
                    <nav>
                      <ul class="mt-2">
                        <li v-if="currentNavItem">
                          <button
                            type="button"
                            class="leading-loosest py-2 px-8 text-base font-bold w-full text-left flex items-center"
                            @click.prevent="exitMobileSubnav"
                          >
                            <base-icon icon-name="angle-left" width="6" height="8" viewbox="0 0 9 17" class="arrow">
                              <icon-angle-left></icon-angle-left>
                            </base-icon>
                            <span class="pl-2">Back</span>
                          </button>
                          <div>
                            <span 
                              class="block leading-loosest pb-2 pt-8 px-8 text-3xl font-bold w-full text-left"
                            >
                              {{ currentNavItem.text }}
                            </span>
                          </div>
                        </li>
                        <template v-for="item in currentMenu">
                            <li>
                              <div v-if="item.type === 'ctaButton'" class="px-8 py-2">
                                <base-button
                                  :url="item.url"
                                  :label="item.text"
                                  :color="item.ctaButton.color"
                                  :outline="item.ctaButton.outlined"
                                  :thin_outline="true"
                                  :target="item.target"
                                  size="wider"
                                  class_names="w-3/5 sm:w-2/5"
                                ></base-button>
                              </div>
                              <a
                                v-else-if="item.children && item.children.length === 0"
                                :class="[
                                  'leading-loosest py-2 px-8 text-lg w-full text-left flex items-center',
                                  item.level === 1 ? 'font-bold' : ''
                                ]"
                                :href="item.url"
                                :target="item.target"
                              >
                                <span>{{ item.text }}</span>
                                <span class="pl-3">
                                  <base-icon icon-name="angle-left" width="6" height="10" viewbox="0 0 9 17" class="arrow">
                                    <icon-angle-right></icon-angle-right>
                                  </base-icon>
                                </span>
                              </a>
                              <button
                                v-else
                                type="button"
                                :class="[
                                  'leading-loosest py-2 px-8 text-lg w-full text-left flex items-center font-bold',
                                  item.level === 1 ? 'font-bold' : ''
                                ]"
                                @click.prevent="enterMobileSubnav(item)"
                              >
                                <span>{{ item.text }}</span>
                                <span class="pl-3">
                                  <base-icon icon-name="angle-left" width="6" height="10" viewbox="0 0 9 17" class="arrow">
                                    <icon-angle-right></icon-angle-right>
                                  </base-icon>
                                </span>
                              </button>
                            </li>
                        </template>

                        <li v-if="utility_nav" class="px-8 py-6">
                          <hr class="border-grey" />
                        </li>
                        <template v-for="item in utility_nav">
                          <li v-show="item.display.value !== 'desktopOnly'">
                            <a
                              :href="item.url"
                              :class="['block leading-loosest py-2 px-8 text-lg', item.navHighlight ? 'bg-red text-white' : '']"
                            >
                              {{ item.mobileText !== "" && item.mobileText !== null ? item.mobileText : item.text }}
                            </a>
                          </li>
                        </template>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

// @group Headers
export default {
  props: ["header_style", "text_color", "logo_url", "logo_dark_src", "logo_light_src", "main_nav", "utility_nav", "logo_alt"],

  data: function() {
    return {
      activeMenu: null,
      mobileMenuActive: false,
      activeMobileMenu: null,
      currentNavItem: null,
      flattenedNavMenu: [],
    };
  },

  components: {
    HeaderCta: () => import(/* webpackChunkName: "header-cta" */ "./HeaderCta.vue"),
    UtilityNav: () => import(/* webpackChunkName: "utility-nav" */ "../nav/UtilityNav.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../BaseIcon.vue"),
    IconAngleLeft: () => import(/* webpackChunkName: "icon-angle-left" */ "../../icons/IconAngleLeft.vue"),
    IconAngleRight: () => import(/* webpackChunkName: "icon-angle-right" */ "../../icons/IconAngleRight.vue"),
    IconCart: () => import(/* webpackChunkName: "icon-angle-right" */ "../../icons/IconCart.vue"),
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../BaseButton.vue"),
  },

  computed: {
    hasRightCtas(navNode) {
    },
    currentMenu() {
      return this.currentNavItem ? this.currentNavItem.children : this.main_nav;
    }
  },

  mounted() {
    this.flattenedNavMenu = this.main_nav.flatMap(n => [n, this.flattenNav(n)]).flat();
  },

  methods: {
    enterMobileSubnav(item) {
      this.currentNavItem = item;
    },
    exitMobileSubnav() {
      if (! this.currentNavItem.parentId) {
        this.currentNavItem = null;
      }

      this.currentNavItem = this.flattenedNavMenu.find(nav => nav.id === this.currentNavItem.parentId);
    },
    toggleDropdown(text) {
      if (this.activeMenu === text) {
        this.activeMenu = null;
      } else {
        this.activeMenu = text;
      }
    },

    closeDropdown() {
      this.activeMenu = null;
    },

    toggleMobileDropdown(text) {
      if (this.activeMobileMenu === text) {
        this.activeMobileMenu = null;
      } else {
        this.activeMobileMenu = text;
      }
    },

    toggleMobileMenu() {
      // close all drop-downs
      this.activeMenu = null;
      this.activeMobileMenu = null;
      this.resetMobileMenu();

      this.mobileMenuActive = !this.mobileMenuActive;
    },

    closeMobileMenu() {
      this.resetMobileMenu();
      this.mobileMenuActive = false;
    },

    resetMobileMenu() {
      this.currentNavItem = null;
    },

    flattenNav(item) {
      const f = item.children.flatMap(this.flattenNav);

      return [f, item].flat(1);
    }
  },

  mixins: [clickaway]
};
</script>

<style>
.menu-list li {
  @apply pt-1;
}

.angle.rotated {
  transform: rotate(180deg);
}

.top-link {
  .text:after {
    content: "";
    height: 3px;
    background-color: #e1251b;
    position: absolute;
    bottom: -13px;
    left: 0;
    right: 100%;
    opacity: 0;
    transition-delay: 0.15s;
    transition-property: left right;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
  }

  .active-text:after {
    content: "";
    height: 3px;
    background-color: #e1251b;
    position: absolute;
    bottom: -13px;
    left: 0;
    right: 0;
    opacity: 1;
  }

  &:hover {
    .text:after {
      right: 0;
      opacity: 1;
    }
  }
}

.reconyx-level {
  @apply w-1/2;
}

.cart-btn {
  .circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    left: 13px;
    top: -4px;
    border: 2px solid #fff;
  }
}

</style>
